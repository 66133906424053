import axios from "axios";
import endpoint from "src/common/services/endpoints";
import { getStoredUser } from "src/common/components/auth/auth";
import { handleErr, handleRes } from "src/common/services/response";

// QUERIES

export const getVideos = async ({ pageNum }) => {
    const token = getStoredUser()?.token;
    pageNum = pageNum || 1;

    const config = {
        method: "get",
        url: `${endpoint.rest}generate/all?orderby=createdate desc&pagenumber=${pageNum}`,
        headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
        },
    };

    try {
        const res = await axios(config);
        return handleRes({ res, config });
    } catch (error) {
        handleErr({ error, config });
    }
};

export const getVideo = async ({ id }) => {
    const token = getStoredUser()?.token;
    const config = {
        method: "get",
        url: `${endpoint.rest}generate/${id}`,
        headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
        },
    };

    try {
        const res = await axios(config);
        return handleRes({ res, config });
    } catch (error) {
        handleErr({ error, config });
    }
};

// MUTATIONS

export const generateVideo = async ({ type, name, text, useVoice, imageURL, videoURL, audioURL, imageId, videoId, voiceId, voiceGender }) => {
    const token = getStoredUser()?.token;
    if (type) {
        const config = {
            method: "post",
            url: `${endpoint.rest}generate/${type}`,
            data: {
                name,
                useVoice,
                text,
                ...(type === "video" && { fix: "yes" }),
                ...(imageURL && { imageURL }),
                ...(videoURL && { videoURL }),
                ...(audioURL && { audioURL }),
                ...(imageId && { imageId }),
                ...(videoId && { videoId }),
                ...(voiceId && { voiceId }),
                ...(voiceGender && { voiceGender }),
            },
            headers: {
                "Content-Type": "application/json",
                Authorization: `${token}`,
            },
        };

        try {
            const res = await axios(config);
            return handleRes({ res, config });
        } catch (error) {
            handleErr({ error, config });
        }
    } else {
        console.log("missing type");
    }
};

export const deleteVideo = async ({ videoId }) => {
    const token = getStoredUser()?.token;

    const config = {
        method: "delete",
        url: `${endpoint.rest}generate/${videoId}`,
        headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
        },
    };

    try {
        const res = await axios(config);
        return handleRes({ res, config });
    } catch (error) {
        handleErr({ error, config });
    }
};

export const bgOverlay = async ({ bgvideo, video, x, y, width, height }) => {
    const token = getStoredUser()?.token;

    const config = {
        method: "post",
        url: `${endpoint.rest}mediabot/overlay`,
        data: {
            mainvideo: bgvideo,
            overlayvideo: video,
            x:`${x}`,
            y:`${x}`,
            width:`${width}`,
            height:`${height}`,
        },
        headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
        },
    };

    try {
        const res = await axios(config);
        return handleRes({ res, config });
    } catch (error) {
        handleErr({ error, config });
    }
};

export const addText = async ({ inputfile, text, x, y, fontsize, font }) => {
    const token = getStoredUser()?.token;

    const config = {
        method: "post",
        url: `${endpoint.rest}mediabot/addtext`,
        data: {
            inputfile,
            text,
            fontsize,
            x,
            y,
        },
        headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
        },
    };

    try {
        const res = await axios(config);
        return handleRes({ res, config });
    } catch (error) {
        handleErr({ error, config });
    }
};
