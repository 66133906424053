import { useEffect, useState } from "react";
import { TbAlertCircle } from "react-icons/tb";
import Dbox from "src/common/components/dbox/dbox";

const AddText = (props) => {
    const { genData } = props;

    const defTexts = [
        {
            id: "001",
            excerpt: `What's up`,
            text: `What's up! It feels like ages since we last talked. How've you been?`,
        },
        {
            id: "002",
            excerpt: `Yo!`,
            text: `Yo! How's life treating you? We should catch up soon!`,
        },
        {
            id: "003",
            excerpt: `Catch up`,
            text: `Hey, what's new? We need to hang out and catch up sometime soon!`,
        },
    ];

    const [defText, setDefText] = useState(null);

    let storedData = {...genData}

    useEffect(() => {
        // const stored = {...genData}
        // if(defText){
        //     genData.text = defText.text
        // }else{
        //     genData.text = ''
        // }
        // props.OnSet({ ...genData });
    },[defText]);

    return (
        <div className="flex flex-col gap-10 py-10">
            <div className="flex flex-col gap-3 text-center">
                <div className="text-xl font-semibold">4. Add Your Text</div>
                <div className="opacity-50">Add text that your video will use. This will be converted as speech.</div>
            </div>

            <Dbox className={`relative  overflow-hidden`}>
                <div className="flex-1 flex flex-col p-20 gap-8">
                    {/* <div>Add some instructions here...</div> */}
                    <div className="border flex  flex-1 border-lightblue100 rounded-2xl relative overflow-hidden">
                        <textarea
                            value={genData.text}
                            className="flex flex-1 w-full p-8 bg-lightblue50"
                            rows={8}
                            placeholder={`Add text for you audio ...`}
                            onChange={(e) => {
                                genData.text = e.target.value;
                                props.OnSet({ ...genData });
                            }}
                        />


                    </div>

                    <div className="flex gap-2 items-center ">
                    <TbAlertCircle className="text-lg text-amber-400" />{" "}
                    <span className="opacity-40">For increased realism in your avatar script, please ensure proper punctuation is used.</span>
                </div>

                    <div className="flex flex-col gap-5 pt-5">
                        <div>Try some of our default texts : </div>
                        <div className="flex flex-row gap-4">
                            {defTexts.map((dtext, d) => (
                                <div
                                    key={d}
                                    className={`transition-all px-3 py-1 border rounded-full cursor-pointer ${
                                        dtext.id === defText?.id ? `bg-blue text-white border-blue ` : `border-lightblue100  hover:bg-lightblue50`
                                    }`}
                                    onClick={() => {
                                        
                                        if(dtext.id === defText?.id){
                                            setDefText(null)
                                            genData.text = storedData.text
                                            props.OnSet({ ...genData });
                                        }else{
                                            setDefText({ ...dtext });

                                            genData.text = dtext.text
                                            props.OnSet({ ...genData });
                                        }
                                        
                                    }}
                                >
                                    {dtext.excerpt}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </Dbox>
        </div>
    );
};

export default AddText;
