const Tag = (props) => {
    const { value } = props;
    
    let bg;
    let color;

    switch (value) {
        case "active":
            bg = "teal-50";
            color = "teal-400";
            break;
        case "finished":
            bg = "teal-50";
            color = "teal-400";
            break;
        case "done":
            bg = "teal-50";
            color = "teal-400";
            break;   
        case "Done":
            bg = "teal-50";
            color = "teal-400";
            break;     
        case "ready":
            bg = "teal-50";
            color = "teal-400";
            break;

        case "sent":
            bg = "teal-50";
            color = "teal-400";
            break;
        case "stopped":
            bg = "pink-50";
            color = "pink-400";
            break;
        case "error":
            bg = "pink-50";
            color = "pink-400";
            break;
        case "onhold":
            bg = "orange-50";
            color = "orange-300";
            break;
        case "processing":
            bg = "orange-50";
            color = "orange-300";
            break;
        case "generating":
            bg = "orange-50";
            color = "orange-300";
            break;
        default:
            bg = "lightblue100";
            color = "blue";
            break;
    }

    return (
        <span
            className={`transition-all nline flex-0 items-center ${props.className ? props.className : ""}`}
            onClick={() => {
                if (props.onClick) {
                    props.onClick(value)
                }
            }}
        >
            <span className={`rounded-3xl flex-0 px-3 py-1 ${(props.color)? `bg-${props.color}-50 text-${props.color}-400` : `bg-${bg} text-${color}` }`}>{value?.toLowerCase()}</span>
        </span>
    );
};

export default Tag;
