import { useEffect, useState } from "react";
import ListGen from "src/modules/admin/generated-videos/list-gen";
import { IoChevronBackOutline } from "react-icons/io5";
import ListVideos from "src/modules/admin/inputed-videos/list-videos";
import { IoAddCircleOutline } from "react-icons/io5";
import { upload } from "src/common/services/utils";

const SelectAvatar = (props) => {
    const { theVideo, setTheVideo, OnBack, setSelElem } = props;
    const [isUploading, setIsUploading] = useState(false);
    const tabs = [
        {
            id: "gen",
            label: "Generated Videos",
        },
        {
            id: "input",
            label: "Your Videos",
        },
    ];

    const [selectedTab, setSelectedTab] = useState(tabs[0]);

    const handleUpload = async ({ file }) => {
        setIsUploading(true)
        try {
            const upload_res = await upload({ file, network: "inputvideo" });
            console.log("upload_res ::", upload_res);
            setSelectedTab(tabs[1])
            // setTheVideo((prev) => ({
            //     ...prev,
            //     avatar: {
            //         ...prev.avatar,
            //         video: {
            //             url: upload_res.data[0].cache,
            //         },
            //     },
            //     script: {
            //         ...prev.script,
            //         text: "",
            //         voice: null,
            //     },
            // }));
        } catch (error) {
            console.log(error);
        }
        setIsUploading(false)
    };

    return (
        <div className="flex-1 flex flex-col">
            <div className="flex flex-col pb-6 p-8 sticky top-0 z-20 bg-white gap-6">
                <div className="flex flex-row gap-2 items-center">
                    {theVideo.avatar.video && (
                        <>
                            <div
                                className="flex opacity-40 hover:opacity-100 cursor-pointer"
                                onClick={() => {
                                    OnBack(true);
                                }}
                            >
                                <IoChevronBackOutline className="text-lg cursor-pointer" />
                            </div>
                        </>
                    )}
                    <div className="text-lg flex-1">Select Avatar</div>

                    <label
                        className={`relative overflow-hidden transition-all bg-white border rounded-full p-2 pr-5 flex items-center gap-2 
                                    ${
                                        isUploading
                                            ? "text-blue border-blue"
                                            : "opacity-60 hover:opacity-100 hover:text-blue hover:border-blue border-zinc-500/40 cursor-pointer"
                                    }
                                `}
                    >
                        {!isUploading ? (
                            <>
                                <IoAddCircleOutline className="text-xl" />
                                <div>Upload</div>
                            </>
                        ) : (
                            <div className="loading pl-2">Uploading</div>
                        )}

                        {!isUploading && (
                            <input
                                type="file"
                                title=""
                                accept={`video/*`}
                                className="absolute z-50 left-0 top-0 opacity-0 w-full h-full cursor-pointer"
                                onChange={async (e) => {
                                    await handleUpload({ file: e.target.files[0] });
                                }}
                            />
                        )}
                    </label>
                </div>

                <div className="flex flex-row items-center justify-center text-center bg-lightblue80 rounded-2xl p-2 ">
                    {tabs.map((tab, t) => (
                        <div
                            key={t}
                            className={`transition flex-1 cursor-pointer ${tab.id === selectedTab.id ? "opacity-100 bg-white rounded-xl py-2" : "opacity-50"}`}
                            onClick={() => {
                                setSelectedTab({ ...tab });
                            }}
                        >
                            <div className="font-semibold">{tab.label}</div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="flex flex-1 flex-col min-w-full px-10 relative z-10 pb-10">
                {selectedTab.id === "gen" && (
                    <ListGen
                        cols={2}
                        thumbsize={"300"}
                        selectedId={theVideo.avatar.video?.id}
                        for={`select`}
                        paging={"manual"}
                        OnUse={(video) => {
                            let thevideo = null;
                            if (video.id) {
                                thevideo = video;
                                delete video.createdate;
                            } else {
                                setSelElem({
                                    type: "avatar",
                                    value: null,
                                });
                            }

                            setTheVideo((prev) => ({
                                ...prev,
                                avatar: {
                                    ...prev.avatar,
                                    video: thevideo,
                                },
                                script: {
                                    ...prev.script,
                                    text: thevideo?.text,
                                    voice: {
                                        voiceId: thevideo?.voiceId,
                                    },
                                },
                            }));
                            OnBack(true);
                        }}
                    />
                )}

                {selectedTab.id === "input" && (
                    <ListVideos 
                        cols={2}
                        thumbsize={"300"}
                        selectedId={theVideo.avatar.video?.id}
                        for={`select`}
                        paging={"manual"}
                        OnUse={(video) => {
                            let thevideo = null;
                            if (video.id) {
                                thevideo = video;
                                delete video.createdate;
                            }
                            setTheVideo((prev) => ({
                                ...prev,
                                avatar: {
                                    ...prev.avatar,
                                    video: thevideo,
                                },
                                script: {
                                    ...prev.script,
                                    text: "",
                                    voice: null,
                                },
                            }));
                            OnBack(true);
                        }}
                    />
                )}
            </div>
        </div>
    );
};

export default SelectAvatar;
