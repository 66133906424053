import { useRef, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const More = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const ref = useRef(null);
    const { video } = props;

    useEffect(() => {
        const handleClickOutside = (event) => {
            // event.stopPropagation();
            if (ref.current && !ref.current.contains(event.target)) {
                props.OnClose(true);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div className="more-box absolute top-0 left-0 w-full h-full z-30 slideFromLeftFast">
            <div
                ref={ref}
                className="absolute bottom-4 py-4 min-w-52 right-4 bg-white shadow-lg shadow-indigo-600/10 border border-zinc-500/20 rounded-xl z-20 flex flex-col gap-2"
            >
                {video.result !== "" && (
                    <>
                        <div
                            className="px-6 py-1 transition cursor-pointer hover:opacity-70 hover:translate-x-0.5"
                            onClick={() => {
                                const thevideo = { ...video };
                                delete thevideo.id;
                                delete thevideo.createdate;
                                delete thevideo.result;
                                delete thevideo.state;
                                // thevideo.type = "regenerate";
                                // navigate(`${location.pathname}`, { state: { show: "create-video", data: thevideo } });
                                navigate("/admin/generate-video", { state: { todo: "regen", data: thevideo } });
                            }}
                        >
                            Re-generate
                        </div>

                        <a
                            href={video.result}
                            target="_blank"
                            download={video.result}
                            className="px-6 py-1 transition cursor-pointer hover:opacity-70 hover:translate-x-0.5 hover:text-black border-b border-indigo-500/20 pb-3 "
                        >
                            Download
                        </a>
                    </>
                )}

                <div
                    className="px-6 transition cursor-pointer hover:opacity-70 hover:translate-x-0.5 text-rose-500"
                    onClick={() => {
                        const thevideo = { ...video };
                        thevideo.TYPE = "gen";
                        navigate(`${location.pathname}`, { state: { show: "delete", data: thevideo } });
                        // props.OnDelete(video);
                    }}
                >
                    Delete
                </div>
            </div>
        </div>
    );
};

export default More;
