import { useEffect, useState, useRef } from "react";

import { dateFormatter, toEpoch } from "src/common/utils/utils";
import { deleteVideo, getVideo } from "src/common/services/generate";
import { getQueue } from "src/common/services/utils";
import logoIcon from "src/common/assets/images/logo-white.svg";
import Dbox from "src/common/components/dbox/dbox";
import VideoPlayer from "src/common/components/video-player/video-player";

import More from "./card-gen-more";

import { IoMdRefresh } from "react-icons/io";
import { RiMore2Fill } from "react-icons/ri";
import { useLocation, useNavigate } from "react-router-dom";
import { TbShare2 } from "react-icons/tb";
import { BiErrorAlt } from "react-icons/bi";

const CardGen = (props) => {
    const { selectedId } = props;
    const navigate = useNavigate();
    const location = useLocation();

    const [video, setVideo] = useState(props.video);
    const [showMore, setShowMore] = useState();

    const [timedOut, setTimedOut] = useState(false);
    const [fetching, setFetching] = useState(false);
    const pollTimeout = useRef();
    const pollCounter = useRef(0);
    const pollLimit = 20;

    useEffect(() => {
        return () => {
            if (pollTimeout.current) {
                clearTimeout(pollTimeout.current);
            }
        };
    }, []);

    useEffect(() => {
        pollStatus();
    }, [video.state]);

    useEffect(() => {
        if (video.result) {
            video.result = video.result;
        }
    }, [video.result]);

    const pollStatus = async () => {
        pollCounter.current++;
        if (pollCounter.current <= pollLimit) {
            if (pollTimeout.current) {
                clearTimeout(pollTimeout.current);
            }

            if (video.state === "processing" || video.state === "") {
                try {
                    // const vid_res = await getQueue({ id: video.id });
                    // console.log("poll queue status :", vid_res);
                    const vid_res = await getVideo({ id: video.id });
                    console.log("vid_res :", vid_res[0]);

                    setVideo({ ...vid_res[0] });

                    if (vid_res[0].state === "complete") {
                        navigate("/admin/generated-videos", { state: { reload: true } });
                    } else {
                        if (vid_res[0].state.indexOf("error") === -1) {
                            pollTimeout.current = setTimeout(async () => {
                                clearTimeout(pollTimeout.current);
                                await pollStatus();
                            }, 6000);
                        }
                    }
                } catch (error) {
                    console.log(error);
                }
            }
        } else {
            console.log("timed out");
            setTimedOut(true);
        }
    };

    const handleVideo = async ({ opr, video, genId }) => {
        switch (opr) {
            case "get":
                if (genId) {
                    setFetching(true);
                    const get_res = await getVideo(genId);
                    console.log("get_res :", get_res);
                    setFetching(false);
                }
                break;

            case "delete":
                if (genId) {
                    const del_res = await deleteVideo({ genId });
                    console.log("del_res :", del_res);
                    props.OnDelete(true);
                }
                break;
            case "onuse":
                if (video) {
                    const thevideo = { ...video };

                    if (thevideo.id === selectedId) {
                        props.OnUse({});
                    } else {
                       
                        thevideo.type = "video";
                        props.OnUse({ ...thevideo });
                    }
                }
                break;
            default:
                break;
        }
    };

    return (
        <div
            className={`transition-all flex flex-col relative overflow-hidden border-4 border-lightblue100/50  rounded-3xl
            ${props.for === 'select' && selectedId !== video.id ? "hover:border-4 hover:border-indigo-500" : ""}
            ${props.for === 'select' && selectedId === video.id ? "border-4 !border-indigo-500" : ""}`}
        >
            <div
                className={`transition-all min-h-${(props.thumbsize)? props.thumbsize : '600'} flex flex-col  relative overflow-hidden rounded-2xl ${
                    props.for && selectedId === video.id ? "border-8 border-lightblue100 rounded-3xl" : ""
                }`}
            >
                <div
                    className={`relative max-h-full flex-1 cursor-pointer ${selectedId === video.id ? "bg-blue" : "bg-zinc-950"}`}
                    onClick={() => {
                        if (video.result) {
                            {
                                props.for === "select" && handleVideo({ opr: "onuse", video });
                            }
                            {
                                !props.for && navigate(location.pathname, { state: { show: "detail-generated", data: video } });
                            }
                        }
                    }}
                >
                    {video.result !== "" && (
                        <VideoPlayer
                            image={`${video.result}/frame1.jpg`}
                            src={video.result}
                            hoverPlay={true}
                            actions={false}
                            className={`${selectedId === video.id ? "opacity-50" : ""}`}
                            fluid={true}
                            fit={"cover"}
                        />
                    )}

                    {video.result === "" && (video.state === "" || video.state === "processing") && (
                        <div className="flex-1  h-full flex flex-col gap-3 items-center justify-center flex-1 ">
                            <img src={logoIcon} className={`w-1/3  blinking`} />
                            <div className="text-white loading opacity-60">Generating video</div>
                        </div>
                    )}

                    {(video.state?.indexOf(`internal server error`) !== -1 || video.state?.indexOf(`no face detected`) !== -1) && (
                        <div className="flex-1  h-full flex flex-col gap-3 items-center justify-center flex-1 text-center px-6">
                            <BiErrorAlt className="text-3xl text-amber-300" />
                            <div className="text-white capitalize opacity-60">{video.state}</div>
                        </div>
                    )}

                    <div
                        className="absolute w-full h-24 top-0 left-0 z-20"
                        style={{
                            background: `rgb(0,0,0)`,
                            background: `linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.3) 100%)`,
                        }}
                    ></div>
                </div>

                <div className={`info-box p-5 pr-3 bg-white flex gap-4 items-center justify-between ${props.for ? "cursor-pointer" : ""}`}>
                    <div className="flex flex-col justify-center">
                        <div>{video.name}</div>
                        <div className="opacity-50 text-xs">{dateFormatter(video.createdate)}</div>
                    </div>

                    {!props.for && (
                        <div>
                            <RiMore2Fill
                                className="text-black text-xl cursor-pointer "
                                onClick={() => {
                                    setShowMore(true);
                                }}
                            />
                        </div>
                    )}


                    {props.actions && (
                        <div>
                            {props.actions}
                        </div>
                    )}
                </div>

                {showMore && (
                    <More
                        video={video}
                        OnClose={() => {
                            setShowMore(false);
                        }}
                    />
                )}

                <div className="p-3 absolute top-0 left-0 w-full flex justify-end z-20">
                    {/* <div className="bg-black/60  rounded-3xl px-3 py-1 flex items-center gap-2 text-white text-xs">{video.name}</div> */}
                    {video.result && !props.for && (
                        <div
                            className="p-2 cursor-pointer"
                            onClick={() => {
                                navigate(location.pathname, { state: { show: "share", data: video } });
                            }}
                        >
                            <TbShare2 className="text-white text-xl" />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default CardGen;
