import React, { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FaPlay } from "react-icons/fa";
import { FaPause } from "react-icons/fa";
import { dateFormatter } from "src/common/utils/utils";
import defaultPic from "src/common/assets/images/default_pic_small.png";
import { deleteVoice } from "src/common/services/voices";
import { IoIosArrowForward } from "react-icons/io";

import { RiMore2Fill } from "react-icons/ri";
import More from "./card-voice-more";

const CardAudio = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { voice, selectedId } = props;

    const [showMore, setShowMore] = useState();
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);

    const audioRef = useRef(null);
    const progressRef = useRef(null);

    useEffect(() => {
        const audio = audioRef.current;
        const onLoadedMetadata = () => setDuration(audio.duration);
        const onTimeUpdate = () => setCurrentTime(audio.currentTime);
        audio.addEventListener("loadedmetadata", onLoadedMetadata);
        audio.addEventListener("timeupdate", onTimeUpdate);
        return () => {
            audio.removeEventListener("loadedmetadata", onLoadedMetadata);
            audio.removeEventListener("timeupdate", onTimeUpdate);
        };
    }, []);

    const handleVoice = async ({ opr, voiceId, voice }) => {
        switch (opr) {
            case "delete":
                if (voiceId) {
                    const del_res = await deleteVoice({ voiceId });
                    console.log("del_res :", del_res);

                    // props.OnDelete(true);
                }
                break;
            case "onuse":
                if (voice) {
                    const thevoice = { ...voice };

                    if (thevoice.voiceId === selectedId) {
                        props.OnUse({});
                    } else {
                        thevoice.type = "voice";
                        props.OnUse({ ...thevoice });
                    }
                }
                break;

            default:
                break;
        }
    };

    const togglePlayPause = () => {
        const audio = audioRef.current;
        if (isPlaying) {
            audio.pause();
        } else {
            audio
                .play()
                .then(() => {})
                .catch((error) => {
                    console.log("error playing video :", error);
                });
        }
        setIsPlaying(!isPlaying);
    };

    const handleProgressClick = (e) => {
        const progress = progressRef.current;
        const clickX = e.nativeEvent.offsetX;
        const width = progress.offsetWidth;
        const newTime = (clickX / width) * duration;
        audioRef.current.currentTime = newTime;
    };

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
    };

    return (
        <div
            className={`transition-all  audio-player flex flex-col bg-white border-4 border-lightblue100/50 rounded-3xl relative overflow-hidden hover:border-lightblue100/80
            ${props.for && selectedId !== voice.voiceId ? "hover:border-4 hover:!border-indigo-500/50" : ""}
            ${props.for && selectedId === voice.voiceId ? "border-4 !border-indigo-500 !bg-indigo-500/5" : ""}
        `}
        >
            {showMore && (
                <More
                    {...props}
                    thesrc={voice.sample}
                    voice={voice}
                    OnClose={() => {
                        setShowMore(false);
                    }}
                />
            )}

            {voice.sample && !props.for && (
                <div
                    className="absolute right-0 top-3 bg-white rounded-full p-3 cursor-pointer"
                    onClick={() => {
                        setShowMore(true);
                    }}
                >
                    <RiMore2Fill className="text-black text-xl" />
                </div>
            )}
            {voice.sample && props.for === "next" && (
                <div
                    className="absolute right-0 top-3 bg-white rounded-full p-3 cursor-pointer"
                    onClick={() => {
                        if (voice.sample) {
                            handleVoice({ opr: "onuse", voice });
                        }
                    }}
                >
                    <IoIosArrowForward className="text-black text-xl" />
                </div>
            )}

            <div
                className={`p-4 flex border-b border-lightblue100 gap-4 
                    ${props.for ? "cursor-pointer" : ""}`}
                onClick={() => {
                    if (voice.sample) {
                        handleVoice({ opr: "onuse", voice });
                    }
                }}
            >
                <div className="aspect-square w-14 h-14 rounded-xl relative overflow-hidden">
                    {props.image && <img src={props.image} className="object-cover w-full h-full" />}
                    {!props.image && voice.sample !== "" && (
                        <img
                            src={voice.sample + "/frame1.jpg"}
                            className="object-cover w-full h-full"
                            onError={(e) => {
                                e.target.src = defaultPic; // Fallback image
                                // e.target.alt = "Image not available"; // Optional: Set alt text
                            }}
                        />
                    )}
                </div>
                <div className="flex flex-col justify-center">
                    <div>{voice.voiceName}</div>
                    <div className="opacity-50 text-xs">{dateFormatter(voice.date)}</div>
                </div>
            </div>
            <div className="flex-1 w-full flex flex-row items-center gap-5 p-4 relative">
                <audio ref={audioRef} src={voice.sample} />
                <button
                    className={`bg-lightblue80 max-w-14 max-h-14 min-w-14 min-h-14 flex rounded-full items-center justify-center relative z-10
                ${props.for && selectedId === voice.voiceId ? "!bg-white" : ""}
                    `}
                    onClick={togglePlayPause}
                >
                    {isPlaying ? <FaPause className="text-base text-blue" /> : <FaPlay className="text-base text-blue" />}
                </button>

                <div
                    ref={progressRef}
                    onClick={handleProgressClick}
                    className={`progress-bar relative overflow-hidden w-full h-2 bg-indigo-500/10 cursor-pointer rounded-full z-10`}
                >
                    <div
                        className="progress bg-blue absolute h-full"
                        style={{
                            width: `${(currentTime / duration) * 100}%`,
                        }}
                    />
                </div>

                <div className="time text-nowrap text-xs relative z-10">
                    <span>{formatTime(currentTime)}</span> / <span>{formatTime(duration)}</span>
                </div>
            </div>
        </div>
    );
};

export default CardAudio;
