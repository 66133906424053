import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";

import AdminMobileHeader from "src/modules/admin/admin-mobile-header";
import AdminSidebar from "src/modules/admin/admin-sidebar";
import UserCard from "src/modules/admin/user/user-card";
import Popup from "src/common/components/popup/popup";
// import Create from "src/modules/admin/generated-videos/create-video";

import CreateVoice from "src/modules/admin/voices/create-voice";
import DetailGenerated from "src/modules/admin/generated-videos/detail-gen";
import ApiBox from "src/common/components/api-box";
import DetailVideo from "src/modules/admin/inputed-videos/detail-video";
import DetailImage from "src/modules/admin/inputed-images/detail-image";
import DetailVoice from "src/modules/admin/voices/detail-voice";
import Share from "src/common/components/share/share";
import LogBox from "src/common/components/log-box";
import UiDelete from "src/modules/shared/ui-delete";

const AdminLayout = (props) => {
    const navigate = useNavigate();
    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);
    const isDev = queryParams.get("dev") === "true";

    const [show, setShow] = useState(null);
    const [popClass, setPopClass] = useState(``);

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (location.pathname !== "/admin/generate-video") {
                navigate(location.pathname);
            }
        };
        window.addEventListener("beforeunload", handleBeforeUnload);
        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, []);

    useEffect(() => {
        if (location?.state?.show) {
            switch (location?.state?.show) {
                case "create-video":
                    setPopClass(`md:min-w-912`);

                    break;
                case "detail-generated":
                    setPopClass(`md:min-w-80p`);

                    break;
                case "share":
                    setPopClass(`popup-share`);

                    break;
                case "delete":
                    setPopClass(`popup-small`);

                    break;
                default:
                    setPopClass(`md:min-w-400`);
                    break;
            }

            setShow(location?.state?.show);
        }
    }, [location]);

    return (
        <div className="container bg-lightblue50 max-w-full h-full flex flex-col md:flex-row p-0 m-0">
            {isDev && <LogBox />}

            {show && (
                <Popup
                    OnHide={() => {
                        setShow(false);
                    }}
                    className={popClass}
                >
                    {/* {show === "create-video" && (
                        <Create
                            onUse={location?.state?.data}
                            OnSuccess={() => {
                                setShow(false);
                                navigate("/admin/generated-videos", { state: { reload: true } });
                            }}
                        />
                    )} */}
                    {show === "create-voice" && (
                        <CreateVoice
                            onUse={location?.state?.data}
                            OnSuccess={() => {
                                setShow(false);
                                navigate("/admin/voices", { state: { reload: true } });
                            }}
                        />
                    )}

                    {show === "detail-generated" && <DetailGenerated video={location?.state?.data} />}
                    {show === "detail-video" && <DetailVideo video={location?.state?.data} />}
                    {show === "detail-image" && <DetailImage image={location?.state?.data} />}
                    {show === "detail-voice" && <DetailVoice voice={location?.state?.data} />}

                    {show === "api-box" && (
                        <div className="p-8 flex flex-col gap-5 min-h-600">
                            <div className="text-xl font-medium tracking-snug">API</div>
                            <ApiBox data={location?.state?.data} />
                        </div>
                    )}

                    {show === "share" && <Share video={location?.state?.data} />}

                    {show === "delete" && (
                        <UiDelete
                            data={location?.state?.data}
                            OnClose={() => {
                                setShow(false);
                            }}
                        />
                    )}
                </Popup>
            )}

            {props.beforeContent && props.beforeContent}

            {props.type !== "full" && <AdminSidebar />}

            <div className="flex flex-1 flex-col">
                {props.header && (
                    <div className="flex top-0 bg-white border-b border-b-lightblue100 px-10 min-h-32 items-center relative z-20 justify-between gap-8 ">
                        <div className="flex-1 flex flex-col max-w-screen-2xl mx-auto">{props.header(true)}</div>
                        <UserCard />
                    </div>
                )}

                <div
                    className={`flex flex-1 p-4 md:p-8 relative w-full relative z-10 ${
                        props.type !== "full" && props.type !== "full-sidebar" ? "max-w-screen-2xl mx-auto" : ""
                    }`}
                >
                    {props.children}
                </div>

                {props.footer && (
                    <div className="flex sticky bottom-0 bg-white border-t border-t-lightblue100 p-8 relative  z-20">
                        <div className="flex-1 flex flex-col max-w-screen-2xl mx-auto">{props.footer(true)}</div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default AdminLayout;
