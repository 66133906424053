import { useEffect } from "react";
import { PiAlignLeftSimple } from "react-icons/pi";
import { PiAlignCenterHorizontalSimple } from "react-icons/pi";
import { PiAlignRightSimple } from "react-icons/pi";

import { PiAlignTopSimple } from "react-icons/pi";
import { PiAlignCenterVerticalSimple } from "react-icons/pi";
import { PiAlignBottomSimple } from "react-icons/pi";

const AvatarPosition = (props) => {
    const { theVideo, setTheVideo, stageSize } = props;

    const positions = {
        hor: [
            {
                id: "left",
                icon: <PiAlignLeftSimple className="text-xl" />,
                x: 0,
            },
            {
                id: "center",
                icon: <PiAlignCenterHorizontalSimple className="text-xl" />,
                x: stageSize.width / 2 - theVideo.avatar.video.width / 2,
            },
            {
                id: "right",
                icon: <PiAlignRightSimple className="text-xl" />,
                x: stageSize.width - theVideo.avatar.video.width,
            },
        ],

        vert: [
            {
                id: "top",
                icon: <PiAlignTopSimple className="text-xl" />,
            },
            {
                id: "middle",
                icon: <PiAlignCenterVerticalSimple className="text-xl" />,
            },
            {
                id: "bottom",
                icon: <PiAlignBottomSimple className="text-xl" />,
            },
        ],
    };

    // const  handleUpdate = ()=>{
    //     setTheVideo((prev)=> ({
    //         ...prev,
    //         avatar:{
    //             ...prev.avatar,
    //             alignment:{
    //                 hor:
    //             }
    //         }
    //     })
    // )

    return (
        <div className="flex-1 w-full flex flex-col gap-4">
            <div>Position</div>
            <div className="flex-1 w-full flex flex-row gap-4 justify-between">
                <div className="flex-1 flex flex-row gap-4   border border-lightblue100 rounded-2xl p-3 justify-between">
                    {positions.hor.map((pos, a) => (
                        <div
                            key={a}
                            className={`transition p-3 flex cursor-pointer rounded-xl hover:bg-lightblue100/40`}
                            onClick={() => {
                                setTheVideo((prev) => ({
                                    ...prev,
                                    avatar: {
                                        ...prev.avatar,
                                        x: pos.x,
                                    },
                                }));
                            }}
                        >
                            {pos.icon}
                        </div>
                    ))}
                </div>
                <div className="flex-1 flex flex-row gap-4  border border-lightblue100 rounded-2xl p-3 justify-between">
                    {positions.vert.map((pos, a) => (
                        <div
                            key={a}
                            className={`transition p-3 flex cursor-pointer rounded-xl hover:bg-lightblue100/40`}
                            onClick={() => {
                                // setTheVideo((prev) => ({
                                //     ...prev,
                                //     avatar: {
                                //         ...prev.avatar,
                                //         alignment: {
                                //             ...prev.avatar.alignment,
                                //             vert: pos.id,
                                //         },
                                //     },
                                // }));
                            }}
                        >
                            {pos.icon}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default AvatarPosition;
