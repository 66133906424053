import { useEffect, useState } from "react";
import { IoMdClose } from "react-icons/io";

import FormInput from "../form/input";
import FormPhone from "../form/phone";
import { MdOutlineMarkEmailRead } from "react-icons/md";

import { MMS, sendEmail } from "src/common/services/share";
import Preloader from "../preloader/preloader";
import VideoPlayer from "../video-player/video-player";

const ShareEmail = (props) => {
    const { video } = props;
    const [onSent, setOnSent] = useState(null);
    const [fetching, setFetching] = useState(false);

    const [emailData, setSmsData] = useState({
        sender_name: "",
        recipient_email: "",
        recipient_name: "",
        subject: `Hey! Check this out.`,
        content: `I found something really cool that I think you'll love. It's worth your time, so give it a look when you can!`,
        attachment: JSON.stringify({ attachment1: video?.result }),
    });

    useEffect(() => {
        if (onSent === true) {
            const timeout = setTimeout(() => {
                props.OnClose(true);
                clearTimeout(timeout);
            }, 1500);
        }
    }, [onSent]);

    const sendMessage = async () => {
        const edata = { ...emailData };

        edata.content = edata.content + ` <a href="${video?.result}" target="_blank">Check this out</a>`;

        if ((edata && edata.sender_name !== "") || edata.recipient_name !== "" || edata.recipient_email !== "") {
            setFetching(true);
            try {
                const send_res = await sendEmail(edata);
                console.log(send_res);
                setOnSent(true);
            } catch (error) {
                console.log("error:", error);
            }
            setFetching(false);
        }
    };
    return (
        <>
            {fetching && <Preloader />}
            {!onSent && (
                <div className="flex flex-col gap-10 p-10">
                    <div className="flex items-start justify-between gap-4 mb-4">
                        <div className="text-xl font-medium tracking-snug">Share via Email</div>
                        <IoMdClose
                            className="text-xl opacity-40 hover:opacity-100 cursor-pointer"
                            onClick={() => {
                                props.OnClose(true);
                            }}
                        />
                    </div>

                    <div className="flex flex-col gap-5">
                        <FormInput
                            type={"text"}
                            label={"Your Name:"}
                            placeholder={`Add Your Name`}
                            value={emailData.sender_name}
                            onChange={(e) => {
                                emailData.sender_name = e.target.value;
                                setSmsData({ ...emailData });
                            }}
                        />

                        <div className="flex flex-col gap-3">
                            <div className="font-semibold">Send to : </div>
                            <div className="bg-lightblue50 border border-lightblue100 rounded-2xl flex flex-col  py-1">
                                <div className="flex gap-3 items-center">
                                    <div className="min-w-20 pl-4">Name:</div>
                                    <input
                                        type="text"
                                        className="border-b border-lightblue100 pl-0"
                                        placeholder="Recipient Name"
                                        value={emailData.recipient_name}
                                        onChange={(e) => {
                                            emailData.recipient_name = e.target.value;
                                            setSmsData({ ...emailData });
                                        }}
                                    />
                                </div>

                                <div className="flex gap-3 items-center">
                                    <div className="min-w-20 pl-4">Email:</div>
                                    <input
                                        type="text"
                                        className="border-b border-lightblue100 pl-0"
                                        placeholder="Recipient Email"
                                        value={emailData.recipient_email}
                                        onChange={(e) => {
                                            emailData.recipient_email = e.target.value;
                                            setSmsData({ ...emailData });
                                        }}
                                    />
                                </div>

                                <div className="flex gap-3 items-center">
                                    <div className="min-w-20 pl-4">Subject:</div>
                                    <input
                                        type="text"
                                        className=" pl-0"
                                        placeholder="Subject"
                                        value={emailData.subject}
                                        onChange={(e) => {
                                            emailData.subject = e.target.value;
                                            setSmsData({ ...emailData });
                                        }}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="font-semibold">Message : </div>
                        <div className="flex flex-col gap-3">
                            <div className="bg-lightblue50 border border-lightblue100 rounded-2xl flex flex-col  p-6 gap-8">
                                <textarea
                                    className={`flex-1 p-2`}
                                    rows={3}
                                    // label={"Message :"}
                                    value={emailData.content}
                                    onChange={(e) => {
                                        emailData.content = e.target.value;
                                        setSmsData({ ...emailData });
                                    }}
                                />

                                {/* <FormInput
                                type={"textarea"}
                                className={`flex-1`}
                                // label={"Message :"}
                                value={emailData.content}
                                onChange={(e) => {
                                    emailData.content = e.target.value;
                                    setSmsData({ ...emailData });
                                }}
                            /> */}
                                <div className="border border-dashed border-blue rounded-2xl p-2 bg-lightblue100 relative overflow-hidden flex items-center justify-start gap-5 cursor-not-allowed">
                                    <div className="aspect-squre h-16 w-16 opacity-70">
                                        <img src={video?.result + `/frame1.jpg`} className="bg-black rounded-xl w-full h-full object-cover" />
                                    </div>
                                    <div className="flex-1 opacity-70">... {video?.result?.slice(-30)}</div>
                                </div>
                            </div>
                        </div>
                        <button
                            className="button"
                            disabled={emailData.sender_name === "" || emailData.recipient_name === "" || emailData.recipient_email === ""}
                            onClick={async () => await sendMessage()}
                        >
                            Send Email
                        </button>
                    </div>
                </div>
            )}

            {onSent && (
                <div className="flex-1 min-h-72 flex flex-col items-center justify-center gap-10">
                    <MdOutlineMarkEmailRead className="text-teal-500 text-6xl" />
                    <div>Email sent successfully.</div>
                </div>
            )}
        </>
    );
};

export default ShareEmail;
