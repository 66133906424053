import { useState, useEffect } from "react";
import { getStoredUser } from "src/common/components/auth/auth";
import FormInput from "src/common/components/form/input";
import logoIconWhite from "src/common/assets/images/logo-icon-white.svg";
import { upload } from "src/common/services/utils";
import { updateUser } from "src/common/services/user";
import Preloader from "src/common/components/preloader/preloader";

const SettingSMS = (props) => {
    const [fetching, setFetching] = useState(false);
    const [user, setUser] = useState({
        name: "",
        description: "",
        phone: "",
    });

    useEffect(() => {
        if (getStoredUser() && getStoredUser().id) {
            console.log(getStoredUser());
            setUser(getStoredUser());
        }
    }, []);

    useEffect(() => {
        console.log("user ::", user);
        if (user) {
        }
    }, [user]);

    const handleUpload = async (file) => {
        if (file) {
            setFetching(true);
            try {
                const upload_res = await upload({ file });
                if (upload_res?.data[0]) {
                    const thekey = upload_res?.data[0].key;
                    const thebucket = upload_res?.data[0].s3.split("/")[2];
                    user.tkey = thekey;
                    user.tbucket = thebucket;
                    setUser({ ...user });
                }
            } catch (error) {
                console.log(error);
            }
            setFetching(false);
        }
    };

    const handleSave = async () => {
        setFetching(true);
        try {
            const update_res = await updateUser(
                getStoredUser().id,
                {
                    name: user.name,
                    description: user.description,
                    phone: user.phone,
                    tkey: user.tkey,
                    tbucket: user.tbucket,
                },
                true
            );

            localStorage.setItem("DIGITALU_user", JSON.stringify(update_res));
        } catch (error) {
            console.log(error);
        }
        setFetching(false);
    };

    return (
        <div className="flex flex-1 flex-start flex-col gap-8">
            {fetching && <Preloader />}
            <div className="text-lg">SMS</div>
            <div className="flex-1 flex flex-col gap-8">
                <FormInput
                    label={`Phone:`}
                    type={`text`}
                    value={user?.phone}
                    onChange={(e) => {
                        user.phone = e.target.value;
                        setUser({ ...user });
                    }}
                />
            </div>

            <div className="">
                <button
                    className="button"
                    onClick={async () => {
                        await handleSave();
                    }}
                >
                    Save
                </button>
            </div>
        </div>
    );
};

export default SettingSMS;
