import { useState } from "react";
import VideoPlayer from "src/common/components/video-player/video-player";
import { LuImage } from "react-icons/lu";
import { LuVideo } from "react-icons/lu";

const SelectBgGallery = (props) => {
    const { theVideo, setTheVideo } = props;

    const gallery = [
        { type: "image", value: "https://svc.staging.squadz.live/download/1/1/O99AqTcfeb498/img1.jpg" },
        { type: "image", value: "https://svc.staging.squadz.live/download/1/1/O99Ar67ac3486/img2.jpg" },
        { type: "image", value: "https://svc.staging.squadz.live/download/1/1/O99ArM004e255/img3.jpg" },
        { type: "image", value: "https://svc.staging.squadz.live/download/1/1/O99Are1044e1f/img4.jpg" },
        { type: "image", value: "https://svc.staging.squadz.live/download/1/1/O99As3489f6cb/img5.jpg" },

        { type: "video", value: "https://svc.staging.squadz.live/download/1/1/O99B014e31316/1.mp4" },
        { type: "video", value: "https://svc.staging.squadz.live/download/1/1/O99B0b9a23ebd/2.mp4" },
        { type: "video", value: "https://svc.staging.squadz.live/download/1/1/O99B1X94837ed/3.mp4" },
    ];

    return (
        <div className="flex-1 flex flex-col">
            <div className="gap-6 flex flex-col justify-start">
                <div className="flex flex-row items-center justify-between">
                    <div>Gallery</div>
                    <div className="flex flex-row gap-2 items-center"></div>
                </div>

                <div className="grid grid-cols grid-cols-3 gap-3">
                    {gallery.map((item, g) => (
                        <div
                            key={g}
                            className={`cursor-pointer  min-h-200 border-4 rounded-2xl relative overflow-hidden
                                ${item.value === theVideo.bg.value ? "border-blue" : " border-white"}
                                `}
                            onClick={() => {
                                if (item.value !== theVideo.bg.value) {
                                    setTheVideo((prev) => ({
                                        ...prev,
                                        bg: item,
                                    }));
                                } else {
                                    setTheVideo((prev) => ({
                                        ...prev,
                                        bg: {
                                            type: "color",
                                            value: "#ffffff",
                                        },
                                    }));
                                }
                            }}
                        >
                            <div
                                className="absolute left-0 top-0 p-2 z-20 w-full min-h-24"
                                style={{
                                    background: `rgb(0,0,0)`,
                                    background: `linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.3) 100%)`,
                                }}
                            >
                                {item.type === "image" && <LuImage className="text-lg text-white" />}
                                {item.type === "video" && <LuVideo className="text-lg text-white" />}
                            </div>
                            {item.type === "image" && (
                                <img
                                    src={item.value}
                                    className={`object-cover
                                ${item.value === theVideo.bg.value ? "opacity-40" : ""}
                                `}
                                />
                            )}

                            {item.type === "video" && (
                                <div
                                    className={`w-full h-full object-cover
                                    ${item.value === theVideo.bg.value ? "opacity-40" : ""}
                                    `}
                                >
                                    <VideoPlayer
                                        image={`${item.value}/frame1.jpg`}
                                        src={item.value}
                                        hoverPlay={true}
                                        actions={false}
                                        fluid={true}
                                        fit={"cover"}
                                    />
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default SelectBgGallery;
