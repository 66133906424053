import React, { useRef, useState, useEffect } from "react";
import { Rect, Group, Image, Layer } from "react-konva";
import useImage from "use-image";

const BgLayer = (props) => {
    const { onChange, theVideo, stageSize, selElem, setSelElem } = props;
    let theimage = null;
    switch (theVideo.bg.type) {
        case "image":
            theimage = theVideo.bg.value;
            break;
        case "video":
            theimage = theVideo.bg.value + "/frame1.jpg";
            break;
        default:
            break;
    }

    const [image] = useImage(theimage); // Load the background image if type is image
    const imageRef = useRef();

    const [imageProps, setImageProps] = useState({});
    const [rectProps, setRectProps] = useState({});

    // Function to resize the image to cover the group
    useEffect(() => {
        if (image) {
            
            const imgWidth = image.width;
            const imgHeight = image.height;
            // Calculate the scale to cover the entire group
            const widthScale = stageSize.width / imgWidth;
            const heightScale = stageSize.height / imgHeight;
            const scale = Math.max(widthScale, heightScale);

            const newWidth = imgWidth * scale;
            const newHeight = imgHeight * scale;


            setImageProps({
                width: newWidth,
                height: newHeight,
                x: (stageSize.width - newWidth) / 2, // Center the image horizontally
                y: (stageSize.height - newHeight) / 2, // Center the image vertically
            });
        }
    }, [image, stageSize]);

    // Function to center the Rect when bg type is color
    useEffect(() => {
        const rectWidth = stageSize.width;
        const rectHeight = stageSize.height;

        setRectProps({
            x: (stageSize.width - rectWidth) / 2, // Center the rect horizontally
            y: (stageSize.height - rectHeight) / 2, // Center the rect vertically
            width: rectWidth,
            height: rectHeight,
        });
    }, [stageSize]);

    const handleSelect = () => {
        setSelElem({
            type: "bg",
            value: theVideo.bg,
        });
    };

    return (
        <Layer>
            {theVideo.bg.type !== "color" && image && <Image image={image} ref={imageRef} {...imageProps} />}
            {theVideo.bg.type === "color" && (
                <Rect
                    x={0}
                    y={0}
                    width={4000}
                    height={4000}
                    // draggable
                    //  {...rectProps}
                    onClick={handleSelect}
                    fill={theVideo.bg.value}
                    onDragEnd={(e) => onChange(e.target)}
                    onTransformEnd={(e) => onChange(e.target)}
                />
            )}
        </Layer>
    );
};

export default BgLayer;
