import { useEffect, useState } from "react";
import Dbox from "src/common/components/dbox/dbox";
import AdminLayout from "src/layouts/admin-layout";
import SettingProfile from "src/modules/admin/settings/profile";
import SettingSMS from "src/modules/admin/settings/sms";
import { FiUser } from "react-icons/fi";
import { MdOutlineSms } from "react-icons/md";


const Settings = () => {
    const settingsMenu = [
        {
            id: "profile",
            name: "Profile",
            icon: <FiUser className="text-lg" />,
        },

        {
            id: "sms",
            name: "SMS",
            icon: <MdOutlineSms className="text-lg" />,
        },
    ];
    const [selMenu, setSelMenu] = useState(settingsMenu[0]);

    return (
        <AdminLayout
            header={() => (
                <div className="flex flex-1 items-center ">
                    <div className="flex flex-1 flex-row px-0 items-center gap-6">
                        <div className="text-xl font-semibold">Settings</div>
                    </div>
                </div>
            )}
        >
            <div className="container mx-auto flex flex-col flex-1 h-full max-w-screen-lg ">
                <Dbox className={`p-16  min-h-full`}>
                    <div className="flex flex-1  flex-row">
                        <div className="settings-sidebar flex flex-col max-w-60 min-w-60 gap-6">
                            {settingsMenu.map((item, m) => (
                                <div
                                    key={m}
                                    className={`transition-all cursor-pointer flex gap-3 items-center  ${
                                        selMenu?.id === item.id ? "opacity-100 font-bold border-r-4 border-blue text-blue" : "opacity-40 hover:opacity-100"
                                    } hover:pl-1`}
                                    onClick={() => {
                                        setSelMenu({ ...item });
                                    }}
                                >
                                    {item.icon}
                                    {item.name}
                                </div>
                            ))}
                        </div>

                        <div className="settings-content border-l border-indigo-500/20 pl-16 flex flex-1 min-h-full">
                            {selMenu?.id === "profile" && <SettingProfile />}
                            {selMenu?.id === "sms" && <SettingSMS />}
                        </div>
                    </div>
                </Dbox>
            </div>
        </AdminLayout>
    );
};

export default Settings;
