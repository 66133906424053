import React, { useEffect, useState } from "react";
import { Route, Redirect, useHistory, useNavigate } from "react-router-dom";
import { getStoredUser } from "src/common/components/auth/auth";
import Preloader from "src/common/components/preloader/preloader";
import { getUser } from "src/common/services/user";

const ProtectedRoute = ({ component: Component, ...rest }) => {
    const navigate = useNavigate();
    const [user, setUser] = useState();
    const [fetching, setFetching] = useState(false);

    useEffect(() => {
        setFetching(true);
        //  console.log("protected-route fired");
        let storedUser = getStoredUser();
        console.log(storedUser)
        if (storedUser && storedUser.id) {
            setUser(storedUser);
            localStorage.setItem("DIGITALU_user", JSON.stringify(storedUser));
        } else {
            navigate("/login");
        }

        setFetching(false);
    }, []);

    return (
        <>
            {fetching && <Preloader type={"full"} />}
            {!fetching && user && user.id && <Component {...rest} key={document.location.href} />}
        </>
    );
};

export default ProtectedRoute;
