import { useEffect, useState } from "react";

import { MdKeyboardArrowRight } from "react-icons/md";
import AddText from "./add-text";
import AddAudio from "./add-audio";

import { generateVideo, getVideo } from "src/common/services/generate";
import SidebarNavi from "../sidebar-navi";

const Script = (props) => {
    const { theVideo, setTheVideo, selElem, setSelElem } = props;
    const [fetching, setFetching] = useState(false);
    const [showVoices, setShowVoices] = useState(false);
    const tabs = [
        {
            id: "add-text",
            label: "Text",
            // comp: <AddText {...props} />,
        },
        {
            id: "add-audio",
            label: "Audio",
            //comp: <AddAudio  {...props}  />,
        },
    ];
    const [selectedTab, setSelectedTab] = useState(tabs[0]);

    const handleGenerate = async () => {
        setFetching(true);
        const { avatar, script } = theVideo;
        const { video } = avatar;
        const { voice } = script;

        try {
            const gen_input = {
                ...(video.type && { type: video.type }),
                ...(video.name && { name: video.name + "_" + Date.now() }),
                ...(video.text && { text: video.text }),

                ...(video.videoId && { videoId: video.videoId }),
                ...(voice.voiceId && { voiceId: voice.voiceId }),
                ...(video.imageId && { imageId: video.imageId }),

                ...(video.videoURL && { videoURL: video.videoURL }),
                ...(voice.audioURL && { audioURL: voice.audioURL }),
                ...(video.imageURL && { imageURL: video.imageURL }),

                ...(video.useVoice && { useVoice: video.useVoice }),
            };

            console.log("gen_input (input) ::", gen_input);

            let vid_res;
            if (theVideo.avatar.video?.id) {
                vid_res = await getVideo({ id: theVideo.avatar.video?.id });
                if (vid_res[0]) {
                    delete vid_res[0].createdate;
                }
            }

            if (vid_res && vid_res[0].text === theVideo.avatar.video.text) {
                theVideo.gen_output = vid_res[0];
                theVideo.gen_input = { ...gen_input };

                setTheVideo({ ...theVideo });
            } else {
                const gen_res = await generateVideo(gen_input);
                console.log("gen_res (output) ::", gen_res);

                if (gen_res[0]) {
                    //save output and input
                    theVideo.gen_output = gen_res[0];
                    theVideo.gen_input = { ...gen_input };

                    setTheVideo({ ...theVideo });
                }
            }
        } catch (error) {
            console.log(error);
        }

        setFetching(false);
    };

    return (
        <div className="flex-1 flex flex-col h-full">
            <div className="text-lg p-8 pb-0">Add Your Script</div>
            <div className="flex flex-col p-6 px-8 pb-6 sticky top-0 z-20 bg-white">
                <div className="flex flex-row items-center justify-center text-center bg-lightblue80 rounded-2xl p-2 ">
                    {tabs.map((tab, t) => (
                        <div
                            key={t}
                            className={`transition flex-1 cursor-pointer ${tab.id === selectedTab.id ? "opacity-100 bg-white rounded-xl py-2" : "opacity-50"}`}
                            onClick={() => {
                                setSelectedTab({ ...tab });
                            }}
                        >
                            <div className="font-semibold">{tab.label}</div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="flex-1 flex flex-col gap-4 h-full w-full p-8 pt-0">
                {selectedTab.id === "add-text" && <AddText {...props} showVoices={showVoices} setShowVoices={setShowVoices} />}
                {selectedTab.id === "add-audio" && <AddAudio {...props} />}
            </div>
            {!showVoices && (

                <SidebarNavi 
                    className={`p-8`}
                    OnBack={()=>{
                        setSelElem({
                            type: "avatar",
                            value: null,
                        });
                    }}

                    OnNext={()=>{
                        if (!fetching) {
                            //  await handleGenerate();

                            setSelElem({
                                type: "bg",
                                value: null,
                            });
                        }
                    }}
                />

               
            )}
        </div>
    );
};

export default Script;
