import CardGen from "src/modules/admin/generated-videos/card-gen";
import SelectAvatar from "./select-avatar";
import { useState } from "react";
import AvatarPosition from "./avatar-position";
import AvatarEffects from "./avatar-effects";
import CardVideo from "src/modules/admin/inputed-videos/card-video";

import { MdKeyboardArrowRight } from "react-icons/md";
import SidebarNavi from "../sidebar-navi";

const Avatar = (props) => {
    const { theVideo, selElem, setSelElem } = props;
    const [showAvatars, setShowAvatars] = useState(false);

    if (showAvatars || !theVideo.avatar.video) {
        return (
            <SelectAvatar
                {...props}
                OnBack={() => {
                    setShowAvatars(false);
                }}
            />
        );
    }

    return (
        <div className="flex-1 h-full flex flex-col p-8 gap-6">
            <div className="flex-1 flex flex-col  gap-6">
                <div className="text-lg">Your Avatar</div>
                {theVideo.avatar.video?.result && (
                    <CardGen
                        thumbsize={`400`}
                        for={`studio`}
                        video={theVideo.avatar.video}
                        actions={
                            <button
                                className="button button-small px-4 button-bordered "
                                onClick={() => {
                                    setShowAvatars(true);
                                }}
                            >
                                change
                            </button>
                        }
                        OnUse={(video) => {
                            setShowAvatars(false);
                        }}
                    />
                )}

                {theVideo.avatar.video?.url && (
                    <CardVideo
                        thumbsize={`400`}
                        for={`studio`}
                        video={theVideo.avatar.video}
                        actions={
                            <button
                                className="button button-small px-4 button-bordered "
                                onClick={() => {
                                    setShowAvatars(true);
                                }}
                            >
                                change
                            </button>
                        }
                        OnUse={(video) => {
                            setShowAvatars(false);
                        }}
                    />
                )}

                <div className="flex flex-col rounded-2xl gap-6">
                    <AvatarPosition {...props} />
                </div>

                {/* <div className="flex flex-col  rounded-2xl  gap-6">
                    <AvatarEffects {...props} />
                </div> */}
            </div>

            <SidebarNavi
                OnNext={() => {
                    setSelElem({
                        type: "script",
                        value: null,
                    });
                }}
            />
        </div>
    );
};

export default Avatar;
