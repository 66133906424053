import { useEffect, useState, useRef } from "react";
import { dateFormatter } from "src/common/utils/utils";
import More from "./card-video-more";
import { RiMore2Fill } from "react-icons/ri";

const DetailVideo = (props) => {
    const { video } = props;
    const [showMore, setShowMore] = useState();
    return (
        <div className="bg-white p-4 gap-3 flex flex-col ">
            <div className="max-h-600 relative overflow-hidden rounded-2xl" >
                {video.url && (
                    <video src={video.url} playsInline controls className=" max-h-600  min-h-600 object-contain w-full h-full bg-black" />
                    // <VideoPlayer src={video.url} className="min-w-full h-full" preload={"none"} />
                )}
            </div>

            <div className="flex flex-col px-3 py-8 gap-3 justify-start relative">
                {showMore && (
                    <More
                        thesrc={video.url}
                        video={video}
                        OnClose={() => {
                            setShowMore(false);
                        }}
                    />
                )}
                {video.url && (
                    <div
                        className="bg-white rounded-full p-3 cursor-pointer absolute right-0 top-0"
                        onClick={() => {
                            setShowMore(true);
                        }}
                    >
                        <RiMore2Fill className="text-xl" />
                    </div>
                )}
                <div className="flexp flex flex-row gap-4">
                    <div className="opacity-40">id : </div> <div>{video.id}</div>
                </div>

                <div className="flexp  flex flex-row gap-4">
                    <div className="opacity-40">Upload Date : </div> <div> {dateFormatter(video.uploadDate)}</div>
                </div>

                {/* <div className="flex flex-row justify-between items-center pt-5  pb-2">
                    <div>
                        <a
                            href={video.url}
                            target="_blank"
                            download={video.url}
                            className="button cursor-pointer rounded-3xl py-3 px-6 hover:translate-y-1 flex gap-4 items-center"
                        >
                            Download
                        </a>
                    </div>

                    <div>
                        <button
                            className="button button-gray cursor-pointer rounded-3xl py-3 px-6 hover:translate-y-1 flex gap-4 items-center"
                            onClick={async () => {
                                await handleVideo({ opr: "delete", videoId: video.id });
                            }}
                        >
                            Delete
                        </button>
                    </div>
                </div> */}
            </div>
        </div>
    );
};

export default DetailVideo;
