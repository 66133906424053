import ListVoices from "src/modules/admin/voices/list-voices";
import { IoChevronBackOutline } from "react-icons/io5";
import { useEffect } from "react";

const SelectVoice = ({ theVideo, setTheVideo, OnBack }) => {

    
    return (
        <div className="flex flex-col gap-8">
            <div className="flex flex-row gap-3 items-center">
                <div
                    className="flex"
                    onClick={() => {
                        OnBack(true);
                    }}
                >
                    <IoChevronBackOutline className="text-lg cursor-pointer" />
                </div>

                
                <div className="font-bold ">Select Voice</div>
            </div>

            <div className="flex-1 flex pb-8">
                <ListVoices
                    cols={`2`}
                    selectedId={theVideo.script.voice}
                    for={"select"}
                    OnUse={(voice) => {
                        let thevoice = null;
                        if (voice.voiceId) {
                            thevoice = voice;
                            OnBack(true);
                        }

                        setTheVideo((prev) => ({
                            ...prev,
                            script: {
                                ...prev.script,
                                voice: thevoice,
                            },
                        }));
                        //delete theVideo.script.audioURL;
                    }}
                />
            </div>
        </div>
    );
};

export default SelectVoice;
