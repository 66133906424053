import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import OnBottom from "src/common/components/on-bottom/on-bottom";
import Preloader from "src/common/components/preloader/preloader";

import CardCampaign from "./card-campaign";

const ListCampaigns = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [campaigns, setCampaigns] = useState([
        {
            id: "001",
            name: "My first campaign",
            genId: "O8L7mHbcecadf",
            csv: "https://svc.staging.squadz.live/download/O8H7O97be2d90/O8H7O97be2d90/O8LBEnff2c188/janedoe_f9706b44-04b4-493f-ab74-d676d4204124.csv",
            createdate: "2024-09-22 09:36:34.208 +0000 +0000",
            status:'completed'
        },
        {
            id: "002",
            name: "My second campaign",
            genId: "O8L7mHbcecadf",
            csv: "https://svc.staging.squadz.live/download/O8H7O97be2d90/O8H7O97be2d90/O8LBEnff2c188/janedoe_f9706b44-04b4-493f-ab74-d676d4204124.csv",
            createdate: "2024-09-22 09:36:34.208 +0000 +0000",
            status:'processing'
        },
        {
            id: "0013",
            name: "My third campaign",
            genId: "O8L7mHbcecadf",
            csv: "https://svc.staging.squadz.live/download/O8H7O97be2d90/O8H7O97be2d90/O8LBEnff2c188/janedoe_f9706b44-04b4-493f-ab74-d676d4204124.csv",
            createdate: "2024-09-22 09:36:34.208 +0000 +0000",
             status:'completed'
        },
    ]);
    const [pageNum, setPageNum] = useState(1);
    const [fetching, setFetching] = useState(null);

    useEffect(() => {
        handleCampaigns({ opr: "list", pageNum: 1 });
    }, [location]);

    const handleCampaigns = async ({ opr, pageNum }) => {
        setFetching(true);
        switch (opr) {
            case "list":
                try {
                    //const list_res = await getVideos({ pageNum });
                    // console.log(`list_res ${pageNum}:: `, list_res);

                    // if (location?.state?.reload) {
                    //     setCampaigns([]);
                    //     await new Promise((res) => setTimeout(() => res(), 10));
                    //     navigate(location.pathname);
                    // }

                    // if (list_res && pageNum > 1) {
                    //     setCampaigns([...campaigns, ...list_res]);
                    // } else {
                    //     setCampaigns([...list_res]);
                    // }

                    // if (list_res.length > 0) {
                    //     setPageNum(pageNum + 1);
                    // }
                } catch (error) {
                    console.log(error);
                }

                break;
            default:
                break;
        }
        setFetching(false);
    };
    return (
        <>
            <div className="min-h-full h-full relative overflow-hidden slideFromTop">
                {fetching && pageNum === 1 && <Preloader />}

                <OnBottom
                    OnBottom={async (val) => {
                        //console.log("bottom");
                        if (pageNum > 1) {
                            // await handleVideos({ opr: "list", pageNum });
                        }
                    }}
                >
                    {campaigns?.length > 0 && (
                        <div className="grid grid-cols-4 gap-6 py-6 ">
                            {campaigns.map((campaign, c) => (
                                <CardCampaign
                                    {...props}
                                    key={c}
                                    campaign={campaign}
                                    OnDelete={async () => {
                                        // await handleVideos({ opr: "list", pageNum: 1 });
                                    }}
                                />
                            ))}
                        </div>
                    )}
                </OnBottom>
                {campaigns?.length <= 0 && fetching !== null && (
                    <div className="flex h-full flex-1 items-center justify-center">
                        <div className="flex flex-col gap-4 items-center">
                            {/* <div className="border-2 border-indigo-500/10 rounded-full w-28 h-28 flex items-center justify-center mb-6"><CiImageOff className="text-6xl text-indigo-500/20 " /></div> */}
                            You have not generate any campaigns yet.
                            <button
                                className="button button-small px-4"
                                onClick={() => {
                                    navigate(`/admin/generate-video`, { state: { todo: "create" } });
                                }}
                            >
                                Generate
                            </button>
                        </div>
                    </div>
                )}
            </div>
            {/* <div></div> */}
        </>
    );
};

export default ListCampaigns;
