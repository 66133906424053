import { useLocation, useNavigate } from "react-router-dom";
import AdminLayout from "src/layouts/admin-layout";
import ListGen from "src/modules/admin/generated-videos/list-gen";
import { all } from "src/common/data/apis-generate";


const GeneratedVideos = (props) => {
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <>
            <AdminLayout
                header={() => (
                    <div className="flex flex-1 flex-col ">
                        <div className="flex flex-1 flex-row px-0 items-center">
                            <div className="flex items-center gap-5">
                                <div className="flex-1 text-xl font-semibold">Your Generated Videos</div>
                                {/* <div
                                    className="transition-all bg-white border border-zinc-500/40 rounded-full p-2 px-5  flex items-center gap-2 opacity-60 hover:opacity-100 hover:text-blue hover:border-blue cursor-pointer"
                                    onClick={() => {
                                        navigate(location.pathname, { state: { show: "api-box", data: all() } });
                                    }}
                                >
                                    <div>Api</div>
                                </div> */}
                            </div>

                            {/* <button className="button flex items-center gap-5" onClick={() => {
                                setShowCreate(true)
                            }}>
                                <div>Generate Video</div>
                                <PiVideoBold className="text-xl" />
                            </button> */}
                        </div>
                    </div>
                )}
            >
                <div className="container mx-auto flex  flex-col flex-1 h-full">
                    <ListGen />
                </div>
            </AdminLayout>
        </>
    );
};

export default GeneratedVideos;
