import { useEffect, useRef } from "react";
import SelectBgGallery from "./select-bg-gallery";
import SelectBgSolid from "./select-bg-solid";
import { getVideo } from "src/common/services/generate";
import SidebarNavi from "../sidebar-navi";

const BG = (props) => {
    const { theVideo, setTheVideo, selElem, setSelElem, selScreen } = props;
    const pollTimeout = useRef();
    const pollCounter = useRef(0);
    const pollLimit = 200;

    useEffect(() => {
        console.log("theVideo.gen_output :: ", theVideo.gen_output);
        pollStatus();
    }, []);

    const pollStatus = async () => {
        pollCounter.current++;
        if (pollCounter.current <= pollLimit) {
            if (pollTimeout.current) {
                clearTimeout(pollTimeout.current);
            }

            if (theVideo.gen_output && (theVideo.gen_output?.state === "processing" || theVideo.gen_output?.state === "" || !theVideo.gen_output?.state)) {
                try {
                    const vid_res = await getVideo({ id: theVideo.gen_output.id });
                    // console.log("vid_res :", vid_res[0]);

                    theVideo.gen_output = vid_res[0];
                    setTheVideo({ ...theVideo });

                    if (vid_res[0].state === "complete") {
                        console.log("completed");
                    } else {
                        if (vid_res[0].state.indexOf("error") === -1) {
                            pollTimeout.current = setTimeout(async () => {
                                clearTimeout(pollTimeout.current);
                                await pollStatus();
                            }, 6000);
                        }
                    }
                } catch (error) {
                    console.log(error);
                }
            } else {
                console.log("have not generated yet");
            }
        } else {
            console.log("timed out");
        }
    };

    return (
        <>
            <div className="flex-1 h-full flex flex-col p-8 pb-20 gap-6">
                <div className="flex flex-col gap-6">
                    <div className="text-lg">Select a Background</div>
                </div>
                <div className={`transition-all flex flex-col border rounded-2xl border-lightblue100 p-5`}>
                    <SelectBgSolid {...props} />
                </div>

                <div className={`transition-all flex flex-col border rounded-2xl border-lightblue100 p-5`}>
                    <SelectBgGallery {...props} />
                </div>

                <SidebarNavi
                    className={`py-8 bg-white`}
                    OnBack={() => {
                        setSelElem({
                            type: "script",
                            value: null,
                        });
                    }}
                    OnNext={() => {
                        theVideo.overlay_input = "init";
                        setTheVideo({ ...theVideo });

                        setSelElem({
                            type: "text",
                            value: null,
                        });
                    }}
                />
            </div>
        </>
    );
};

export default BG;
