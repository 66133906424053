import VideoPlayer from "src/common/components/video-player/video-player";
import { TbAlertCircle } from "react-icons/tb";
import { GrUploadOption } from "react-icons/gr";

const AddAudio = (props) => {
    const { theVideo, setTheVideo } = props;
    return (
        <div className="flex flex-1 flex-col gap-6 ">
            <div className="flex flex-1 w-full min-h-24 border border-dashed border-blue rounded-2xl bg-lightblue80 items-center justify-center  relative overflow-hidden">
                {theVideo.script.voice?.audio && theVideo.script.voice?.audio.name && (
                    <div className="max-h-400 min-h-400 aspect-square flex flex-col items-center justify-center p-4 gap-4">
                        {/* <div className="bg-white rounded-full p-6">
                            <HiOutlineSpeakerWave className="text-xl" />
                        </div> */}

                        {theVideo.script.voice.audio.name && (
                            <VideoPlayer
                                src={URL.createObjectURL(theVideo.script.voice.audio)}
                                fluid={true}
                                className="object-contain min-w-14 min-h-14 rounded-2xl relative overflow-hidden bg-black"
                            />
                        )}
                        {/* <video src={URL.createObjectURL(theVideo.audio)} playsInline muted className="max-h-full rounded-2xl relative overflow-hidden" /> */}
                        <div className="flex flex-row items-center gap-5">
                            <div>{theVideo.script.voice.audio.name}</div>
                            <button
                                className="button button-small button-white px-3 py-1 text-xs"
                                onClick={() => {
                                    setTheVideo((prev) => ({
                                        ...prev,
                                        script: {
                                            ...prev.script,
                                            voice: null,
                                        },
                                    }));
                                }}
                            >
                                remove
                            </button>
                        </div>
                    </div>
                )}
                <label className="relative cursor-pointer">
                    {!theVideo.script.voice?.audio && (
                        <div className="flex flex-col gap-4 items-center justify-center">
                            <button className="relative z-10 button-white px-3 py-3 pr-5 rounded-3xl cursor-pointer flex  items-center gap-4 ">
                                <GrUploadOption className="text-xl" />
                                Upload Audio
                            </button>

                            <div className="opacity-50">Accepts mp3, mp4 and wav.</div>
                        </div>
                    )}
                    <input
                        type="file"
                        title=""
                        accept={`video/*, audio/*`}
                        className="absolute z-50 left-0 top-0 opacity-0 w-full h-full cursor-pointer"
                        onChange={async (e) => {
                            console.log(e.target.files[0]);

                            setTheVideo((prev) => ({
                                ...prev,
                                script: {
                                    ...prev.script,
                                    voice: {
                                        audio: e.target.files[0],
                                    },
                                },
                            }));
                        }}
                    />
                </label>
            </div>

            <div className="flex gap-4 items-start">
                <div>
                    <TbAlertCircle className="text-lg text-amber-400" />
                </div>
                <span className="opacity-40">Ensure that your audio file is at least 3 seconds longer than your avatar video.</span>
            </div>
        </div>
    );
};

export default AddAudio;
