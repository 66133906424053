import React, { useRef, useState, useEffect } from "react";
import { Stage, Layer, Group } from "react-konva";
import AvatarLayer from "./layers/avatar";
import BgLayer from "./layers/bg";
import TextLayer from "./layers/text";

import { GrUndo } from "react-icons/gr";
import { GrRedo } from "react-icons/gr";

export const Artboard = (props) => {
    const { theVideo, setTheVideo, screens, selScreen, setSelScreen, stageSize, setStageSize, OnHistory, currentIndex, redoStack } = props;
    const containerRef = useRef(null); // Reference to the parent container

    useEffect(() => {
        updateStageSize();
        window.addEventListener("resize", updateStageSize);
        return () => window.removeEventListener("resize", updateStageSize);
    }, [selScreen]);

    const updateStageSize = () => {
        if (containerRef.current) {
            const { offsetWidth, offsetHeight } = containerRef.current;
            const containerAspectRatio = offsetWidth / offsetHeight;
            const screenAspectRatio = selScreen.width / selScreen.height;

            let newWidth, newHeight;
            if (containerAspectRatio > screenAspectRatio) {
                // Fit by height
                newHeight = offsetHeight;
                newWidth = newHeight * screenAspectRatio;
            } else {
                // Fit by width
                newWidth = offsetWidth;
                newHeight = newWidth / screenAspectRatio;
            }
            setStageSize({
                width: newWidth,
                height: newHeight,
            });
        }
    };



    return (
        <div className="artboard-wrapper relative flex flex-col flex-1 min-h-full bg-indigo-300/10 rounded-3xl bg-dots border-2 border-indigo-300/10">
            <div className="artboard absolute w-full h-full left-0 top-0 flex flex-col flex-1 overflow-hidden">
                <div className="flex bg-white rounded-full border-4 border-lightblue100/90 absolute left-5 top-5 z-20">
                    <div className={`flex p-5 py-3 cursor-pointer hover:bg-lightblue100/20 ${currentIndex === 0 ? 'text-black/40':''}`} onClick={()=>{OnHistory('undo')}}>
                        <GrUndo className="text-lg"  />
                    </div>
                    <div className={`flex p-5 py-3 cursor-pointer border-l border-lightblue100 hover:bg-lightblue100/20 ${redoStack.length === 0 ? 'text-black/40':''}`} onClick={()=>{OnHistory('redo')}}>
                        <GrRedo className="text-lg" />
                    </div>
                </div>

                <div className="flex bg-white rounded-full border-4 border-lightblue100/90 absolute right-5 top-5 z-20">
                    {screens.map((screen, s) => (
                        <div
                            key={s}
                            className={`flex p-5 py-3 cursor-pointer 
                            ${s > 0 ? " border-l border-lightblue100" : ""}
                            ${screen.id === selScreen.id ? "bg-lightblue100/50" : "text-black/30 hover:bg-lightblue100/20 "}
                        `}
                            onClick={() => {
                                setSelScreen({ ...screen });
                            }}
                        >
                            {screen.icon}
                        </div>
                    ))}
                </div>

                <div ref={containerRef} className="stage-continer relative flex flex-1 z-10 items-center justify-center">
                    <Stage width={stageSize.width} height={stageSize.height}>
                        <BgLayer {...props} />
                        <AvatarLayer {...props} />
                        <TextLayer {...props} />
                    </Stage>
                </div>
            </div>
        </div>
    );
};

export default Artboard;
