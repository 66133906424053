import { useEffect, useState } from "react";
import { TbAlertCircle } from "react-icons/tb";

import { CgPlayPauseR } from "react-icons/cg";
import SelectVoice from "./select-voice";
import CardAudio from "src/modules/admin/voices/card-audio";
import Slider from "src/common/components/slider/slider";
import { getVoice } from "src/common/services/voices";

const AddText = (props) => {
    const { theVideo, setTheVideo, showVoices, setShowVoices } = props;

    const defTexts = [
        {
            id: "001",
            excerpt: `What's up`,
            text: `What's up! It feels like ages since we last talked. How've you been?`,
        },
        {
            id: "002",
            excerpt: `Yo!`,
            text: `Yo! How's life treating you? We should catch up soon!`,
        },
        {
            id: "003",
            excerpt: `Catch up`,
            text: `Hey, what's new? We need to hang out and catch up sometime soon!`,
        },
    ];

    const [defText, setDefText] = useState(null);
    const [charCount, setCharCount] = useState(theVideo.script?.text?.length ? theVideo.script?.text?.length : 0); // Character count state
    const maxChars = 1000;

    let storedData = { ...theVideo.script };

    const handleTextChange = (e) => {
        const value = e.target.value;
        if (value.length <= maxChars) {
            theVideo.script.text = value;
            setTheVideo({ ...theVideo.script });
            setCharCount(value.length); // Update character count
        }
    };

    useEffect(() => {
        if (theVideo.script.voice?.voiceId) {
            handleVoice({ opr: "get", voiceId: theVideo.script.voice.voiceId });
        }
    }, []);

    const handleVoice = async ({ opr, voiceId }) => {
        switch (opr) {
            case "get":
                const get_res = await getVoice({ voiceId });
                if (get_res[0].sample) {
                    setTheVideo((prev) => ({
                        ...prev,
                        script: {
                            ...prev.script,
                            voice: get_res[0],
                        },
                    }));
                }
                break;

            default:
                break;
        }
    };

    return (
        <>
            {showVoices ? (
                <SelectVoice
                    {...props}
                    OnBack={() => {
                        setShowVoices(false);
                    }}
                />
            ) : (
                <div className="flex-1 flex flex-col gap-4">
                    <div className="border flex flex-col flex-1 border-lightblue100 rounded-2xl relative overflow-hidden bg-lightblue50">
                        <textarea
                            value={theVideo.script.text}
                            className="flex flex-1 w-full p-8"
                            rows={8}
                            placeholder={`Add text for your audio ...`}
                            onChange={handleTextChange}
                        />

                        {/* Default texts */}
                        <div className="flex flex-col gap-3 p-6">
                            {/* <div>Try some of our default texts:</div> */}
                            <div className="flex flex-row gap-4">
                                {defTexts.map((dtext, d) => (
                                    <div
                                        key={d}
                                        className={`transition-all px-3 py-1 border rounded-full cursor-pointer ${
                                            dtext.id === defText?.id ? `bg-blue text-white border-blue ` : `border-lightblue100  hover:bg-lightblue50`
                                        }`}
                                        onClick={() => {
                                            let thetext;
                                            if (dtext.id === defText?.id) {
                                                setDefText(null);
                                                thetext = storedData.text;
                                                setCharCount(storedData.text.length); // Update character count
                                            } else {
                                                setDefText({ ...dtext });
                                                thetext = dtext.text;
                                                setCharCount(dtext.text.length); // Update character count
                                            }

                                            setTheVideo((prev) => ({
                                                ...prev,
                                                script: {
                                                    ...prev.script,
                                                    text: thetext,
                                                },
                                            }));
                                        }}
                                    >
                                        {dtext.excerpt}
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="flex flex-row items-center justify-between gap-2 px-6 py-4 border-t border-lightlblue100">
                            <div className="actions flex-1 flex flex-row">
                                <CgPlayPauseR title={`Pause for 0.5 sec`} className="text-xl cursor-pointer" />
                            </div>

                            <div className="text-right relativetext-sm opacity-50">
                                {charCount} / {maxChars} characters
                            </div>
                        </div>

                        {/* <div className="flex gap-4 items-start">
                            <div>
                                <TbAlertCircle className="text-lg text-amber-400" />
                            </div>
                            <span className="opacity-40">For increased realism in your avatar theVideo.script, please ensure proper punctuation is used.</span>
                        </div> */}
                    </div>

                    {(!theVideo.script.voice || (theVideo.script.voice && !theVideo.script.voice.voiceId)) && (
                        <div
                            className="p-2 bg-lightblue100/50 border-4 border-lightblue100/50 rounded-2xl p-5 text-center min-h-32 flex items-center justify-center"
                            onClick={() => {
                                setShowVoices(true);
                            }}
                        >
                            <button className="button button-white button-small px-6">Select a voice</button>
                        </div>
                    )}

                    {theVideo.script.voice && theVideo.script.voice.sample && (
                        <CardAudio
                            for={"next"}
                            voice={theVideo.script.voice}
                            OnUse={(voice) => {
                                console.log("on use voice::", voice);
                                setShowVoices(true);
                            }}
                        />
                    )}

                    <div className={`transition-all flex flex-col border rounded-2xl border-lightblue100 p-5 gap-6`}>
                        <div className="flex flex-row items-center justify-between">
                            <div>Speed</div>
                            <div>{theVideo.script.speed}</div>
                        </div>

                        <Slider
                            initialValue={theVideo.script.speed}
                            min={0.5}
                            max={1.5}
                            step={0.1}
                            OnValue={(val) => {
                                setTheVideo((prev) => ({
                                    ...prev,
                                    script: {
                                        ...prev.script,
                                        speed: val,
                                    },
                                }));
                            }}
                        />
                    </div>

                    {/* <button className="button mt-10">Next</button> */}
                </div>
            )}
        </>
    );
};

export default AddText;
