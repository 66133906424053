import axios from "axios";
import { getStoredUser } from "src/common/components/auth/auth";
import endpoint from "./endpoints";
import {handleRes, handleErr } from "./response";

///////////////////////////QUERIES

export const getUser = async (input, params) => {
    const token = params?.token || getStoredUser()?.token;

    const config = {
        method: "post",
        url: `${endpoint.rest}`,
        data: {
            Params: {
                sys: "users",
                act: "select",
                pagesize: "1",
                pagenumber: "1",
            },
            Where: input,
        },
        headers: {
            "Content-Type": "application/json",
            "Authorization": `${token}`,
        },
    };

    try {
        const res = await axios(config);
        return handleRes({res, config});
    } catch (error) {
        handleErr({error, config});
    }
};

export const searchUsers = async (input, params) => {
    const token = params?.token || getStoredUser()?.token;
    const config = {
        method: "post",
        url: `${endpoint.rest}`,
        data: {
            Params: {
                sys: "users",
                act: "select",
                pagesize: params?.pagesize || "10",
                pagenumber: params?.pagenumber || "1",
            },
            WhereLike: input,
        },
        headers: {
            "Content-Type": "application/json",
            "Authorization": `${token}`,
        },
    };

    try {
        const res = await axios(config);
        return handleRes({res, config});
    } catch (error) {
        handleErr({error, config});
    }
};

export const getFollowingNetworks = async (input, params) => {
    const token = params?.token || getStoredUser()?.token;
    const config = {
        method: "post",
        url: `${endpoint.rest}`,
        data: {
            Params: {
                sys: "followingnetworks",
                act: "select",
                pagesize: params?.pagesize || "20",
                pagenumber: params?.pagenumber || "1",
            },
            Where: input,
        },
        headers: {
            "Content-Type": "application/json",
            "Authorization": `${token}`,
        },
    };

    try {
        const res = await axios(config);
        return handleRes({res, config});
    } catch (error) {
        handleErr({error, config});
    }
};

export const usersByNetwork = async (input, params) => {
    const token = params?.token || getStoredUser()?.token;
    const config = {
        method: "post",
        url: `${endpoint.rest}`,
        data: {
            Params: {
                sys: "networkmembers",
                act: "select",
                pagesize: params?.pagesize || "20",
                pagenumber: params?.pagenumber || "1",
            },
            Where: input,
        },
        headers: {
            "Content-Type": "application/json",
            "Authorization": `${token}`,
        },
    };

    try {
        const res = await axios(config);
        return handleRes({res, config});
    } catch (error) {
        handleErr({error, config});
    }
};

export const getUserChats = async (params) => {
    const token = params?.token || getStoredUser()?.token;
    const config = {
        method: "post",
        url: `${endpoint.rest}`,
        data: {
            Params: {
                sys: "users",
                act: "select",
                pagesize: params?.pagesize || "20",
                pagenumber: params?.pagenumber || "1",
            },
            WhereLike: {
                name: "%%",
            },
        },
        headers: {
            "Content-Type": "application/json",
            "Authorization": `${token}`,
        },
    };

    try {
        const res = await axios(config);
        return handleRes({res, config});
    } catch (error) {
        handleErr({error, config});
    }
};

///////////////////////////MUTATIONS

export const loginUser = async (phone) => {
    const config = {
        method: "post",
        url: `${endpoint.host}login`,
        data: JSON.stringify({
            Params: {
                sys: "auth",
                act: "login",
            },
            Where: {
                phone,
                email: "mail@mail.com",
            },
        }),
        headers: {
            "Content-Type": "application/json",
        },
    };

    try {
        const res = await axios(config);
        return handleRes({res, config});
    } catch (error) {
        handleErr({error, config});
    }
};

export const logoutUser = async () => {
    const token = getStoredUser()?.token;
    const config = {
        method: "post",
        url: `${endpoint.rest}`,
        data: {
            Params: {
                sys: "auth",
                act: "logout",
                pagesize: "1",
            },
        },
        headers: {
            "Content-Type": "application/json",
            "Authorization": `${token}`,
        },
    };

    try {
        const res = await axios(config);
        return handleRes({res, config});
    } catch (error) {
        handleErr({error, config});
    }
};

export const createUser = async (input) => {
    const token = getStoredUser()?.token;
    const config = {
        method: "post",
        url: `${endpoint.rest}`,
        data: {
            Params: {
                sys: "users",
                act: "insert",
                pagesize: "1",
                pagenumber: "1",
            },
            Values: input,
        },
        headers: {
            "Content-Type": "application/json",
            "Authorization": `${token}`,
        },
    };

    try {
        const res = await axios(config);
        return handleRes({res, config});
    } catch (error) {
        handleErr({error, config});
    }
};

export const updateUser = async (userid, input, returnUser, auth) => {
    const token = auth || getStoredUser()?.token;
    const config = {
        method: "post",
        url: `${endpoint.rest}`,
        data: {
            Params: {
                sys: "users",
                act: "update",
                pagesize: "1",
                pagenumber: "1",
            },
            Where: { id: userid },
            Values: input,
        },
        headers: {
            "Content-Type": "application/json",
            "Authorization": `${token}`,
        },
    };

    try {
        const res = await axios(config);
        if (returnUser) {
            return await getUser({ id: userid });
        } else {
            return handleRes({res, config});
        }
    } catch (error) {
        handleErr({error, config});
    }
};

export const createStreamChatUserToken = async (userId, auth) => {
    const config = {
        method: "post",
        url: `${endpoint.rest}`,
        data: {
            Params: {
                sys: "chat",
                act: "token",
                pagesize: "1",
            },
        },
        headers: {
            "Content-Type": "application/json",
            "Authorization": `${auth}`,
        },
    };

    try {
        const res = await axios(config);
        return handleRes({res, config});
    } catch (error) {
        handleErr({error, config});
    }
};
