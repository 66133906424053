import { getStoredUser } from "../components/auth/auth";

export const all = (thevideo) => {
    return [
        {
            id: "curl",
            query: `curl --location 'https://svc.staging.squadz.live/api/generate/all?orderby=createdate%20desc' \
--header 'x-api-key: ${getStoredUser().id}'`,
            result: "",
        },
        {
            id: "http",
            query: `GET /api/generate/all?orderby=createdate desc HTTP/1.1
Host: svc.staging.squadz.live
x-api-key: ${getStoredUser().id}`,
        },
        {
            id: "javascript-fetch",

            query: `const myHeaders = new Headers();
myHeaders.append("x-api-key", "${getStoredUser().id}");

const requestOptions = {
  method: "GET",
  headers: myHeaders,
  redirect: "follow"
};

fetch("https://svc.staging.squadz.live/api/generate/all?orderby=createdate desc", requestOptions)
  .then((response) => response.text())
  .then((result) => console.log(result))
  .catch((error) => console.error(error));`,
        },
    ];
};

export const generateVideo = (thevideo) => {
    return [
        {
            id: "curl",
            query: `curl --location 'https://svc.staging.squadz.live/api/generate/video'
        --header 'x-api-key: ${getStoredUser().id}'
        --header 'Content-Type: application/json'
        --data '${JSON.stringify(thevideo)}'`,
        },
        {
            id: "http",
            query: `POST /api/generate/video HTTP/1.1
        Host: svc.staging.squadz.live
        x-api-key: ${getStoredUser().id}
    
        Content-Type: application/json
        Content-Length: 482
    
        ${JSON.stringify(thevideo)}`,
        },
        {
            id: "javascript-fetch",
            query: `const myHeaders = new Headers();
        myHeaders.append("x-api-key",  "${getStoredUser().id}");
        myHeaders.append("Content-Type", "application/json");
    
        const raw = JSON.stringify(${JSON.stringify(thevideo)});
    
        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow"
        };
    
        fetch("https://svc.staging.squadz.live/api/generate/video", requestOptions)
          .then((response) => response.text())
          .then((result) => console.log(result))
          .catch((error) => console.error(error));`,
        },
    ];
};
