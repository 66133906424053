import { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Dbox from "src/common/components/dbox/dbox";
import { dateFormatter } from "src/common/utils/utils";
import { deleteInputImage } from "src/common/services/input";
import More from "./card-image-more";

import { RiMore2Fill } from "react-icons/ri";

const CardImage = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { image, genData } = props;
    const [showMore, setShowMore] = useState();

    const vimage = image.url.split("/")[image.url.split("/").length - 1];

    const handleImage = async ({ opr, imageId, image }) => {
        switch (opr) {
            case "delete":
                if (imageId) {
                    const del_res = await deleteInputImage({ imageId });
                    console.log("del_res :", del_res);

                    props.OnDelete(true);
                }
                break;
            case "onuse":
                if (image) {
                    const theimage = { ...image };
                    if (theimage.id === genData?.imageId) {
                        props.OnUse({});
                    } else {
                        theimage.type = "image";
                        props.OnUse({ ...theimage });
                    }
                }
                break;

            default:
                break;
        }
    };

    return (
        <div
            data-id={image.id}
             className={`transition-all flex flex-col relative overflow-hidden border-4 border-lightblue100/50 rounded-3xl
                    ${props.for && genData && genData.imageId !== image.id ? "hover:border-4 hover:border-indigo-500" : ""}
                    ${props.for && genData && genData.imageId === image.id ? "border-4 !border-indigo-500" : ""}
                `}
        >
            <div
                className={`transition-all  min-h-400 max-h-400  flex-1 flex flex-col  relative overflow-hidden rounded-2xl ${
                    props.for && genData && genData.imageId === image.id ? "border-8 border-lightblue100 rounded-3xl" : ""
                }`}
            >
                <div
                    className={`relative flex-1 overflow-hidden cursor-pointer ${genData && genData.imageId === image.id ? "bg-blue" : " bg-zinc-950"}`}
                    onClick={() => {
                        if (image.url) {
                            {
                                props.for === "select" && handleImage({ opr: "onuse", image });
                            }
                            {
                                !props.for && navigate(location.pathname, { state: { show: "detail-image", data: image } });
                            }
                        }
                    }}
                >
                    {image.url && (
                        <img src={image.url} className={`object-contain w-full h-full ${genData && genData.imageId === image.id ? "opacity-50" : ""}`} />
                    )}
                </div>

                <div className={`info-box p-5 pr-3 bg-white flex gap-4 items-center justify-between ${props.for ? "cursor-pointer" : ""}`}>
                    <div className="flex flex-col justify-center">
                        <div>{vimage}</div>
                        <div className="opacity-50 text-xs">{dateFormatter(image.uploadDate)}</div>
                    </div>

                    {!props.for && (
                        <div>
                            <RiMore2Fill
                                className="text-black text-xl cursor-pointer "
                                onClick={() => {
                                    setShowMore(true);
                                }}
                            />
                        </div>
                    )}
                </div>
                {showMore && (
                    <More
                        image={image}
                        OnClose={() => {
                            setShowMore(false);
                        }}
                    />
                )}
            </div>
        </div>
    );
};

export default CardImage;
