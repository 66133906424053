import AdminLayout from "src/layouts/admin-layout";
import { BiUserVoice } from "react-icons/bi";
import ListVoices from "src/modules/admin/voices/list-voices";
import { useLocation, useNavigate } from "react-router-dom";

const Voices = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    return (
        <>
            <AdminLayout
                header={() => (
                    <div className="flex flex-1 items-center justify-between">
                        <div className="flex flex-1 flex-row px-0 items-center gap-6">
                            <div className="flex text-xl font-semibold">Your Voices</div>
                        </div>
                    </div>
                )}
            >
                <div className="container mx-auto flex flex-col flex-1 h-full">
                    <ListVoices />
                </div>
            </AdminLayout>
        </>
    );
};

export default Voices;
