import { MdKeyboardArrowRight } from "react-icons/md";
import { MdKeyboardArrowLeft } from "react-icons/md";
const SidebarNavi = ({ OnNext, OnBack, className }) => {
    return (
        <div className={`flex flex-row sticky bottom-0 ${!OnBack ? "justify-end" : "justify-between"} ${className}`}>
            {OnBack && (
                <button
                    className="button button-gray flex flex-row gap-2 items-center pl-3"
                    onClick={() => {
                        OnBack();
                    }}
                >
                    <MdKeyboardArrowLeft className="text-xl" /> Back
                </button>
            )}

            {OnNext && (
                <button
                    className="button flex flex-row gap-2 items-center pr-3"
                    onClick={async () => {
                        OnNext();
                    }}
                >
                    Next <MdKeyboardArrowRight className="text-xl text-white" />
                </button>
            )}
        </div>
    );
};

export default SidebarNavi;
