import { useLocation, useNavigate } from "react-router-dom";
import AdminLayout from "src/layouts/admin-layout";
import ListCampaigns from "src/modules/admin/campaigns/list-campaigns";

const Campaigns = (props) => {
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <>
            <AdminLayout
                header={() => (
                    <div className="flex flex-1 flex-col ">
                        <div className="flex flex-1 flex-row px-0 items-center gap-6">
                            <div className="flex items-center gap-5">
                                <div className="flex-1 text-xl font-semibold">Your Campaigns</div>
                            </div>

                            <div
                                className={`relative overflow-hidden transition-all bg-white border rounded-full p-2 px-5 flex items-center gap-2 opacity-60 hover:opacity-100 hover:text-blue hover:border-blue border-zinc-500/40 cursor-pointer`}
                                onClick={() => {
                                    navigate("/admin/campaigns/create");
                                }}
                            >
                                <div>Create Campaign</div>
                            </div>
                        </div>
                    </div>
                )}
            >
                <div className="container mx-auto flex  flex-col flex-1 h-full">
                    <ListCampaigns />
                </div>
            </AdminLayout>
        </>
    );
};

export default Campaigns;
