import { logoutUser } from "src/common/services/user";

export const getStoredUser = () => {
    try{
    let storedUser = localStorage.getItem("DIGITALU_user");
    let currentUser = storedUser && storedUser !== "undefined" ? JSON.parse(storedUser) : null;

    let storedToken = localStorage.getItem("DIGITALU_token");
    let currentToken = storedToken && storedToken !== "undefined" ? storedToken : null;

    if (currentUser && currentUser.id && currentToken) {
        currentUser.token = currentToken;
    }
    return currentUser;
    }catch(error){
        console.log(error)
    }
};

export const logout = () => {
    localStorage.removeItem("DIGITALU_user");
    //await logoutUser()
};
