import React, { useRef, useEffect, useState } from "react";
import { Text, Transformer, Group, Layer } from "react-konva";

const TextLayer = (props) => {
    const { theVideo, setTheVideo, stageSize, selElem, setSelElem } = props;

    const shapeRefs = useRef([]);
    const trRef = useRef();

    useEffect(() => {
        // Only update positions if text is not already positioned
        const needsUpdate = theVideo.texts.some((text) => !text.x || !text.y);

        if (needsUpdate && stageSize.width !== 0) {
            const updatedTexts = theVideo.texts.map((text) => {
                const textWidth = shapeRefs.current[text.id]?.width() || 0;
                const textHeight = shapeRefs.current[text.id]?.height() || 0;

                // Center the text only if x or y is not already set
                const xPos = text.x !== undefined ? text.x : (stageSize.width - textWidth) / 2;
                const yPos = text.y !== undefined ? text.y : (stageSize.height - textHeight) / 2;

                return {
                    ...text,
                    x: xPos,
                    y: yPos,
                };
            });

            setTheVideo((prev) => ({
                ...prev,
                texts: updatedTexts,
            }));
        }
    }, [stageSize, theVideo.texts]);

    useEffect(() => {
        if (selElem.type === "text" && selElem.value && selElem.value.id && trRef.current && shapeRefs.current[selElem.value.id]) {
            // Attach the Transformer to the selected text node
            trRef.current.nodes([shapeRefs.current[selElem.value.id]]);
            trRef.current.getLayer().batchDraw();
        }
    }, [selElem]);

    const updateTexts = ({ text, index, node, x, y, width, height }) => {
        const updatedTexts = [...theVideo.texts];
        updatedTexts[index] = {
            ...text,
            x: x ? x : node.x(),
            y: y ? y : node.y(),
            width: width ? width : node.width(),
            height: height ? height : node.height(),
        };
        setTheVideo((prev) => ({
            ...prev,
            texts: updatedTexts,
        }));
    };

    const handleTransform = (text, index) => {
        const node = shapeRefs.current[text.id];
        const updatedTexts = [...theVideo.texts];
        // Get the new width and height after transformation
        const newWidth = node.width() * node.scaleX(); // Adjust for scaling
        const newHeight = node.height() * node.scaleY(); // Adjust for scaling

        node.scaleX(1);
        node.scaleY(1);
        updateTexts({ text, index, node, width: newWidth, height: newHeight });
        // Apply the changes to the text object
        // updatedTexts[index] = {
        //     ...text,
        //     x: node.x(),
        //     y: node.y(),
        //     width: newWidth, // Set new width to the text object
        //     height: newHeight, // Set new height to the text object
        // };
        // Reset the scale so it doesn't affect future transformations
        // node.scaleX(1);
        // node.scaleY(1);

        // setTheVideo((prev) => ({
        //     ...prev,
        //     texts: updatedTexts,
        // }));
    };

    const handleSelect = (text, index) => {
        const node = shapeRefs.current[text.id];
        updateTexts({ text, index, node });

        console.log("bert");

        if (selElem.value?.id !== text.id) {
            setSelElem({
                type: "text",
                value: text,
            });
        } else {
            const node = shapeRefs.current[text.id];
            const stage = node.getStage();
            const stageBox = stage.container().getBoundingClientRect();
            const textPosition = node.getClientRect();
            const textarea = document.createElement("textarea");
            document.body.appendChild(textarea);
            // Apply styles to match text on canvas as close as possible
            textarea.value = text.text;
            textarea.style.position = "absolute";
            textarea.style.top = stageBox.top + textPosition.y + "px";
            textarea.style.left = stageBox.left + textPosition.x + "px";
            textarea.style.fontSize = node.fontSize() + "px";
            textarea.style.border = "none";
            textarea.style.padding = "0px";
            textarea.style.margin = "0px";
            textarea.style.overflow = "hidden";
            textarea.style.background = "none";
            textarea.style.outline = "none";
            textarea.style.resize = "none";
            textarea.style.lineHeight = node.lineHeight();
            textarea.style.fontFamily = node.fontFamily();
            textarea.style.textAlign = node.align();
            textarea.style.color = node.fill();
            textarea.style.zIndex = 999;
            // Set width and height based on text dimensions
            textarea.style.width = node.width() - node.padding() * 2 + "px";
            textarea.style.height = "auto";
            textarea.style.height = textarea.scrollHeight + 3 + "px"; // Initial height
            textarea.focus();
            // Hide the Text node while editing
            node.hide();
            trRef.current.hide(); // Hide the Transformer as well
            trRef.current.forceUpdate(); // Force update to reflect changes
            // Function to remove the textarea
            function removeTextarea() {
                // Set Text node text to textarea value
                node.text(textarea.value);
                // Set Text node size to match
                node.width(textarea.scrollWidth);
                node.height(textarea.scrollHeight);
                textarea.parentNode.removeChild(textarea);
                window.removeEventListener("click", handleOutsideClick);
                node.show(); // Show the Text node again
                // trRef.current.show(); // Show the Transformer again
                // trRef.current.forceUpdate(); // Force update to reflect changes
            }
            // Update Konva `Text` node in real-time as user types
            textarea.addEventListener("input", function () {
                const updatedTexts = theVideo.texts.map((t) => (t.id === text.id ? { ...t, text: textarea.value } : t));
                setTheVideo((prev) => ({
                    ...prev,
                    texts: updatedTexts,
                }));
                node.text(textarea.value); // Update Konva Text node with current value
                // Update width and height of Text node based on textarea content
                node.width(textarea.scrollWidth);
                node.height(textarea.scrollHeight);
            });
            // Handle when user presses "Enter" or "Esc"
            textarea.addEventListener("keydown", function (e) {
                if (e.key === "Enter" && !e.shiftKey) {
                    removeTextarea();
                }
                if (e.key === "Escape") {
                    removeTextarea();
                }
            });
            // Handle resizing
            textarea.addEventListener("keydown", function () {
                textarea.style.height = "auto"; // Reset height
                textarea.style.height = textarea.scrollHeight + 3 + "px"; // Set new height
            });
            function handleOutsideClick(e) {
                if (e.target !== textarea) {
                    removeTextarea();
                }
            }
            setTimeout(() => {
                window.addEventListener("click", handleOutsideClick);
            });
        }
    };

    return (
        <Layer>
            {theVideo.texts.map((text, t) => (
                <React.Fragment key={t}>
                    <Text
                        text={text.text}
                        ref={(node) => (shapeRefs.current[text.id] = node)}
                        fontSize={text.fontsize} // Keep font size constant
                        fill={text.color}
                        draggable
                        x={text.x}
                        y={text.y}
                        width={text.width} // Set width for wrapping
                        height={text.height} // Set height
                        onClick={() => handleSelect(text, t)}
                        onDragEnd={(e) => {
                            const updatedTexts = [...theVideo.texts];
                            updatedTexts[t] = {
                                ...text,
                                x: e.target.x(),
                                y: e.target.y(),
                            };

                            setTheVideo((prev) => ({
                                ...prev,
                                texts: [...updatedTexts],
                            }));
                        }}
                        onTransformEnd={() => handleTransform(text, t)} // Only resize the textbox
                    />

                    {selElem.type === "text" && selElem.value?.id === text.id && (
                        <Transformer
                            ref={trRef}
                            anchorStroke={`#6676FF`}
                            borderStroke={`#6676FF`}
                            borderStrokeWidth={2}
                            anchorStrokeWidth={2}
                            rotateEnabled={false}
                            enabledAnchors={[
                                "top-left",
                                "top-center",
                                "top-right",
                                "middle-left",
                                "middle-right",
                                "bottom-left",
                                "bottom-center",
                                "bottom-right",
                            ]}
                            // enabledAnchors={[]}

                            boundBoxFunc={(oldBox, newBox) => {
                                // Prevent from getting too small
                                if (newBox.width < 10 || newBox.height < 10) {
                                    return oldBox;
                                }
                                return newBox;
                            }}
                        />
                    )}
                </React.Fragment>
            ))}
        </Layer>
    );
};

export default TextLayer;
