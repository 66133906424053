import { useEffect, useState } from "react";
import React from "react";

import { RiMore2Fill } from "react-icons/ri";
import { MdExpandMore } from "react-icons/md";
import { FiPlusCircle } from "react-icons/fi";
import { v4 as uuidv4 } from "uuid";

import { GrFormClose } from "react-icons/gr";

import DetailText from "./detail-text";
import { addText } from "src/common/services/generate";
import SidebarNavi from "../sidebar-navi";

const Text = (props) => {
    const { theVideo, setTheVideo, selElem, setSelElem, stageSize } = props;

    const [selTextId, setSelTextId] = useState(null);

    const handleAddText = (text) => {
        const textObj = {
            id: uuidv4(),
            text: "Your Text",
            font: "Montserrat",
            fontsize: 24,
            color: "#000000",
            // x: (stageSize.width - 56 || 0) / 2,
            // y: (stageSize.height - 56 || 0) / 2,
        };

        setTheVideo((prev) => ({
            ...prev,
            texts: [...prev.texts, textObj],
        }));
        setSelElem({
            type: "text",
            value: textObj,
        });
    };

    const handleOverlyText = async () => {
        try {
            const textData = {
                inputfile: theVideo.overlay_output.result,
                text: theVideo.texts[0].text,
                fontsize: theVideo.texts[0].fontsize,
                x: theVideo.texts[0].x,
                y: theVideo.texts[0].y,
            };

            const add_res = await addText(textData);

            console.log(add_res);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div className="flex-1 flex flex-col p-8 gap-6">
            <div className="flex flex-col  gap-6">
                <div className="text-lg">Add Text</div>
            </div>
            <div className="flex-1 flex flex-col gap-4">
                {theVideo.texts.map((text, t) => (
                    <React.Fragment key={t}>
                        <div
                            className={`transition-all flex flex-col border rounded-2xl ${
                                selElem?.value?.id === text.id ? "border-blue" : "border-lightblue100"
                            } `}
                        >
                            <div className="flex flex-row items-center gap-4 p-5">
                                <div className=" flex flex-col flex-1 relative overflow-hidden">
                                    <textarea
                                        rows={1}
                                        value={text.text}
                                        placeholder="Add Your Text"
                                        className="min-h-6 max-h-32"
                                        onChange={(e) => {
                                            setTheVideo((prev) => ({
                                                ...prev,
                                                texts: prev.texts.map((textItem) => (textItem.id === text.id ? { ...text, text: e.target.value } : textItem)),
                                            }));
                                        }}
                                    />
                                </div>
                                <div className="flex flex-row gap-4">
                                    <div
                                        className="cursor-pointer opacity-40 hover:opacity-100"
                                        onClick={() => {
                                            if (selTextId) {
                                                setSelTextId(null);
                                            } else {
                                                setSelTextId(text.id);
                                            }
                                        }}
                                    >
                                        <MdExpandMore className={`transition text-xl ${selTextId === text.id ? `rotate-180` : ``}`} />
                                    </div>
                                    <div
                                        className="cursor-pointer opacity-40 hover:opacity-100"
                                        onClick={() => {
                                            setTheVideo((prev) => ({
                                                ...prev,
                                                texts: prev.texts.filter((textItem) => textItem.id !== text.id),
                                            }));
                                        }}
                                    >
                                        <GrFormClose className="text-xl" />
                                    </div>
                                </div>
                            </div>

                            {selTextId === text.id && (
                                <DetailText
                                    {...props}
                                    text={text}
                                    OnBack={() => {
                                        setSelTextId(null);
                                    }}
                                />
                            )}
                        </div>
                    </React.Fragment>
                ))}

                <div
                    className="transition flex items-center justify-center  rounded-2xl p-4 cursor-pointer gap-3 bg-lightblue100/20 hover:bg-lightblue100/50 border border-dashed border-lightblue300 text-black/40 hover:text-black/80"
                    onClick={() => {
                        handleAddText();
                    }}
                >
                    <FiPlusCircle className="text-lg" />
                    Add Text
                </div>
            </div>

            <SidebarNavi
                className={`pt-8 bg-white`}
                OnBack={() => {
                    setSelElem({
                        type: "bg",
                        value: null,
                    });
                }}
                OnNext={() => {
                    // if (theVideo.overlay_output?.state === "complete") {
                    //     await handleOverlyText();
                    // }
                }}
            />
        </div>
    );
};

export default Text;
