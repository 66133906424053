import axios from "axios";
import endpoint from "../endpoints";
import { getStoredUser } from "src/common/components/auth/auth";
import { handleResponse } from "../response";

//QUERIES

export const stableDiff = async (prompt) => {
    //if (getStoredUser() && getStoredUser().id) {
    try {
        let query = {
            Params: {
                userid: "11111111-1111-1111-1111-111111111111",
                auth: "xxxx",
                sys: "ai",
                act: "stablediffusion",
                pagesize: "1",
            },
            Values: {
                userid: "11111111-1111-1111-1111-111111111111",
                prompt: prompt,
            },
        };

        const res = await axios.post(endpoint.rest, query);
        return handleResponse(query, res);
    } catch (error) {
        console.log("error :", error);
    }
    //}
};

export const listVoices = async (prompt) => {
    //if (getStoredUser() && getStoredUser().id) {
    try {
        let query = {
            Params: {
                userid: "11111111-1111-1111-1111-111111111111",
                auth: "xxxx",
                sys: "voices",
                act: "select",
                pagesize: "20",
            },
        };

        const res = await axios.post(endpoint.rest, query);
        return handleResponse(query, res);
    } catch (error) {
        console.log("error :", error);
    }
    //}
};

export const liveportrait = async ({modelid, img, driver, useAdminAuth}) => {
     
    const theAuthUser = {
        id: getStoredUser()?.id,
        auth: getStoredUser()?.token,
    };

    if (useAdminAuth === true) {
        theAuthUser.id = `11111111-1111-1111-1111-111111111111`;
        theAuthUser.auth = `xxxx`;
    }

   if (theAuthUser.id && theAuthUser.auth) {
    try {
        let query = {
            Params: {
                userid: theAuthUser.id,
                auth:  theAuthUser.auth,
                sys: "ai",
                act: "liveportrait",
                pagesize: "1",
            },
            Values: {
                modelid: modelid,
                img: img,
                driver,
            },
        };

        const res = await axios.post(endpoint.rest, query);
        return handleResponse(query, res);
    } catch (error) {
        console.log("error :", error);
    }
    }
};


export const liveportraitAudio = async ({modelid, img, audio, useAdminAuth}) => {
     
    const theAuthUser = {
        id: getStoredUser()?.id,
        auth: getStoredUser()?.token,
    };

    if (useAdminAuth === true) {
        theAuthUser.id = `11111111-1111-1111-1111-111111111111`;
        theAuthUser.auth = `xxxx`;
    }

   if (theAuthUser.id && theAuthUser.auth) {
    try {
        let query = {
            Params: {
                userid: theAuthUser.id,
                auth: theAuthUser.auth,
                sys: "ai",
                act: "liveportraitaudio",
                pagesize: "1",
            },
            Values: {
                modelid: modelid,
                bg: 'yes',
                img: img,
                aud: audio,
                
            },
        };

        const res = await axios.post(endpoint.rest, query);
        return handleResponse(query, res);
    } catch (error) {
        console.log("error :", error);
    }
    }
};


export const asynctts = async (voiceid, text) => {
    //if (getStoredUser() && getStoredUser().id) {
    try {
        let query = {
            Params: {
                userid: "11111111-1111-1111-1111-111111111111",
                auth: "xxxx",
                sys: "ai",
                act: "asynctts",
                pagesize: "1",
            },
            Values: {
                elevenlabs: voiceid,
                text: text
            },
        };

        const res = await axios.post(endpoint.rest, query);
        return handleResponse(query, res);
    } catch (error) {
        console.log("error :", error);
    }
    //}
};
