import React, { useEffect, useState } from "react";
import { RxAvatar } from "react-icons/rx";
import { BsChatSquareText } from "react-icons/bs";
import { TbBackground } from "react-icons/tb";
import { BsTextareaT } from "react-icons/bs";
import Avatar from "./avatar/avatar";
import Script from "./script/script";
import BG from "./bg/bg";
import Text from "./text/text";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { useNavigate } from "react-router-dom";
const Sidebar = (props) => {
    const navigate = useNavigate();
    const { theVideo, selElem } = props;

    const tabs = [
        {
            id: "avatar",
            label: "Avatar",
            icon: <RxAvatar className="text-xl" />,
        },
        {
            id: "script",
            label: "Script",
            icon: <BsChatSquareText className="text-xl" />,
        },
        {
            id: "bg",
            label: "BG",
            icon: <TbBackground className="text-xl" />,
        },
        {
            id: "text",
            label: "Text",
            icon: <BsTextareaT className="text-xl" />,
        },
    ];
    const [selectedTab, setSelectedTab] = useState(tabs[0]);

    useEffect(() => {
        const findTab = tabs.find((tab) => tab.id == selElem.type);
        if (findTab) {
            setSelectedTab(findTab);
        }
    }, [selElem]);

    return (
        <div className="sidebar-container flex w-600 h-full gap-4">
            <div className="max-w-24 h-full relative overflow-hidden flex flex-col gap-5 items-center w-full">
                <div
                    className="transition cursor-pointer  w-full  items-start py-3 flex items-center gap-1 opacity-40 hover:opacity-100"
                    onClick={() => {
                        navigate("/admin");
                    }}
                >
                    <MdKeyboardArrowLeft className="text-xl" />
                    Back
                </div>

                {tabs.map((tab, t) => (
                    <div
                        key={t}
                        className={`transition-all cursor-pointer text-center h-24 w-full flex flex-col items-center justify-center rounded-2xl relative overflow-hidden border-4 border-lightblue100/60 gap-1 p-3
                    ${tab.id === selectedTab.id ? "bg-white " : "bg-lightblue100/60 opacity-50 hover:opacity-90"}`}
                        onClick={() => {
                            setSelectedTab({ ...tab });
                        }}
                    >
                        <div>{tab.icon}</div>
                        <div className="text-xs opacity-80">{tab.label}</div>
                    </div>
                ))}
            </div>

            <div className="flex flex-1 h-full bg-white rounded-3xl relative overflow-hidden border-4 border-lightblue100/60">
                <div className="absolute h-full w-full overflow-x-hidden overflow-y-auto flex flex-col">
                    {selectedTab.id === "avatar" && <Avatar {...props} />}

                    {selectedTab.id === "script" && <Script {...props} />}

                    {selectedTab.id === "bg" && <BG {...props} />}

                    {selectedTab.id === "text" && <Text {...props} />}
                </div>
            </div>
        </div>
    );
};

export default Sidebar;
