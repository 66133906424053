import { useEffect, useState, useRef } from "react";
import Dbox from "src/common/components/dbox/dbox";
import { dateFormatter } from "src/common/utils/utils";
import VideoPlayer from "src/common/components/video-player/video-player";
import { RiMore2Fill } from "react-icons/ri";
import Popup from "src/common/components/popup/popup";
import { deleteInputVideo } from "src/common/services/input";
import { useLocation, useNavigate } from "react-router-dom";
import More from "./card-video-more";

const CardVideo = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { video, selectedId } = props;
    const [showMore, setShowMore] = useState();

    const vname = video.url.split("/")[video.url.split("/").length - 1];

    const handleVideo = async ({ opr, videoId, video }) => {
        switch (opr) {
            case "delete":
                if (videoId) {
                    const del_res = await deleteInputVideo({ videoId });
                    console.log("del_res :", del_res);

                    props.OnDelete(true);
                }
                break;
            case "onuse":
                if (video) {
                    const thevideo = { ...video };

                    if (thevideo.id === selectedId) {
                        props.OnUse({});
                    } else {
                        thevideo.type = "video";
                        props.OnUse({ ...thevideo });
                    }
                }
                break;

            default:
                break;
        }
    };

    return (
        <div
            data-id={video.id}
            className={`transition-all flex flex-col relative overflow-hidden border-4 border-lightblue100/50 rounded-3xl
                    ${props.for ==='select' && selectedId !== video.id ? "hover:border-4 hover:border-indigo-500" : ""}
                    ${props.for ==='select'  && selectedId === video.id ? "border-4 !border-indigo-500" : ""}
           `}
        >
            <div
                className={`transition-all min-h-${(props.thumbsize)? props.thumbsize : '600'} flex flex-col  relative overflow-hidden rounded-2xl ${
                    props.for ==='select' && selectedId === video.id ? "border-8 border-lightblue100 rounded-3xl" : ""
                }`}
            >
                <div className={`relative max-h-full rounded-2xl flex-1 cursor-pointer ${  props.for ==='select' && selectedId === video.id ? "bg-blue" : " bg-zinc-950"}`}>
                    {video.url && (
                        <VideoPlayer
                            image={`${video.url}/frame1.jpg`}
                            src={video.url}
                            className={`${ props.for ==='select' && selectedId === video.id ? "opacity-50" : ""}`}
                            rounded={false}
                            fluid={true}
                            fit={"cover"}
                            hoverPlay={true}
                            actions={false}
                            onClick={() => {
                                if (video.url) {
                                    {
                                        props.for === "select" && handleVideo({ opr: "onuse", video });
                                    }
                                    {
                                        !props.for && navigate(location.pathname, { state: { show: "detail-video", data: video } });
                                    }
                                }
                                // setShowPrev(true);
                            }}
                        />
                    )}
                    <div
                        className="absolute w-full h-24 top-0 left-0 z-20"
                        style={{
                            background: `rgb(0,0,0)`,
                            background: `linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.3) 100%)`,
                        }}
                    ></div>
                </div>

                <div className={`info-box p-5 pr-3 bg-white flex gap-4 items-center justify-between ${props.for ? "cursor-pointer" : ""}`}>
                    <div className="flex flex-col justify-center">
                        <div>{vname}</div>
                        <div className="opacity-50 text-xs">{dateFormatter(video.uploadDate)}</div>
                    </div>

                    {!props.for && (
                        <div>
                            <RiMore2Fill
                                className="text-black text-xl cursor-pointer "
                                onClick={() => {
                                    setShowMore(true);
                                }}
                            />
                        </div>
                    )}
                     {props.actions && (
                        <div>
                            {props.actions}
                        </div>
                    )}
                </div>

                {showMore && (
                    <More
                        video={video}
                        OnClose={() => {
                            setShowMore(false);
                        }}
                    />
                )}
            </div>
        </div>
    );
};

export default CardVideo;
