import React, { useRef, useEffect, useState } from "react";
import { Image, Transformer, Group, Layer } from "react-konva";
import useImage from "use-image";

const AvatarLayer = (props) => {
    const { theVideo, setTheVideo, clipFunc, setSelectedId, stageSize, selElem, setSelElem } = props;
    let theimageurl;
    if (theVideo.avatar.video?.result) {
        theimageurl = theVideo.avatar.video?.result;
    } else if (theVideo.avatar.video?.url) {
        theimageurl = theVideo.avatar.video?.url;
    }
    const [image] = useImage(theimageurl + "/frame1.jpg"); // Load the avatar image
    const imageRef = useRef();
    const trRef = useRef();

    const [imageProps, setImageProps] = useState({});

    useEffect(() => {
        if (image) {
            setSelElem({
                type: "avatar",
                value: theVideo.avatar,
            });
            // Calculate the scale factor to fit the image in the stage while maintaining the aspect ratio
            const imgWidth = image.width;
            const imgHeight = image.height;
            const stageRatio = stageSize.width / stageSize.height;
            const imgRatio = imgWidth / imgHeight;

            let newWidth, newHeight;

            if (imgRatio > stageRatio) {
                // Image is wider than the stage, scale based on width
                newWidth = stageSize.width;
                newHeight = (stageSize.width / imgWidth) * imgHeight;
            } else {
                // Image is taller than the stage, scale based on height
                newHeight = stageSize.height;
                newWidth = (stageSize.height / imgHeight) * imgWidth;
            }
           
            setImageProps({
                width: newWidth,
                height: newHeight,
                x: (stageSize.width - newWidth) / 2, // Center the image horizontally
                y: (stageSize.height - newHeight) / 2, // Center the image vertically
            });
        }
    }, [image]);

    useEffect(() => {
        if (selElem.type === "avatar" && selElem.value && trRef.current && imageRef.current) {
            // Attach the Transformer to the image when selected
            trRef.current.nodes([imageRef.current]);
            trRef.current.getLayer().batchDraw(); // Redraw layer to reflect the transformer

            const node = imageRef.current;
            udpateTheVideo(node);
        }
    }, [selElem]);

    const udpateTheVideo = (node) => {
        const scaleX = node.scaleX();
        const scaleY = node.scaleY();
        setTheVideo((prev) => ({
            ...prev,
            avatar: {
                ...prev.avatar,
                x: node.x(),
                y: node.y(),
                width: node.width() * scaleX,
                height: node.height() * scaleY,
            },
        }));
    };

    return (
        <Layer>
            {/* <Group clipFunc={clipFunc}> */}
            <Image
                image={image}
                ref={imageRef}
                {...imageProps} // Apply calculated width, height, x, and y
                draggable
                onMouseDown={() => {
                    setSelElem({
                        type: "avatar",
                        value: theVideo.avatar,
                    });
                }} // Select the avatar when clicked
                onDragEnd={(e) => {
                    const node = e.target;
                    udpateTheVideo(node);
                }}
                onTransformEnd={(e) => {
                    const node = imageRef.current;
                    udpateTheVideo(node);
                }}
            />
            {/* </Group> */}
            {selElem.type === "avatar" && selElem.value && (
                <Transformer
                    ref={trRef}
                    anchorStroke={`#6676FF`}
                    borderStroke={`#6676FF`}
                    borderStrokeWidth={2}
                    anchorStrokeWidth={2}
                    rotateEnabled={false}
                    enabledAnchors={["top-left", "top-right", "bottom-left", "bottom-right"]}
                    boundBoxFunc={(oldBox, newBox) => {
                        // Limit the resizing, e.g., prevent resizing too small
                        if (newBox.width < 50 || newBox.height < 50) {
                            return oldBox;
                        }
                        return newBox;
                    }}
                />
            )}
        </Layer>
    );
};

export default AvatarLayer;
