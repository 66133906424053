import { useState, useRef, useEffect } from "react";
import { IoChevronBackOutline } from "react-icons/io5";
import { HexColorPicker } from "react-colorful";

const DetailText = (props) => {
    const { theVideo, setTheVideo, text, OnBack } = props;
    const sizeDefaults = [11, 12, 13, 14, 15, 16, 20, 24, 32, 36, 40, 48, 64, 96, 128];
    const fontDefaults = ["Arial", "Montserrat"];

    const [showPicker, setShowPicker] = useState(false);
    const pickerRef = useRef(null);

    const updateText = (field, val) => {
        setTheVideo((prev) => ({
            ...prev,
            texts: prev.texts.map((textItem) => (textItem.id === text.id ? { ...text, [field]: val } : textItem)),
        }));
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (pickerRef.current && !pickerRef.current.contains(event.target)) {
                setShowPicker(false);
            }
        };

        if (showPicker) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [showPicker]);

    return (
        <>
            <div className="flex flex-col gap-6 border-t border-lightblue100 p-5">
                <div className="flex flex-row items-center justify-between relative z-50">
                    <div className="">Font color</div>
                    <div className="flex flex-col relative min-w-72 z-50">
                        <div
                            className="w-10 h-10 rounded-lg"
                            style={{ backgroundColor: `${text.color}` }}
                            onClick={() => {
                                setShowPicker(!showPicker);
                            }}
                        ></div>
                        {showPicker && (
                            <div
                                className="absolute left-0 top-12 bg-white p-4 rounded-2xl slideFromBot border border-lightblue100  w-72 z-40 shadow-xl shadow-lightblue100"
                                ref={pickerRef}
                            >
                                <div className="custom-picker">
                                    <HexColorPicker
                                        color={text.color}
                                        onChange={(val) => {
                                            updateText("color", val);
                                        }}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                <div className="flex flex-row items-center justify-between  relative z-30">
                    <div className="">Font family</div>
                    <div className="border flex flex-col border-lightblue100 rounded-xl relative overflow-hidden bg-lightblue50 min-w-72">
                        <select
                            value={text.font}
                            className="p-3"
                            onChange={(e) => {
                                updateText("font", e.target.value);
                            }}
                        >
                            {fontDefaults.map((font, f) => (
                                <option key={f} value={font}>
                                    {font}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>

                <div className="flex flex-row items-center justify-between  relative z-20">
                    <div className="">Font size</div>
                    <div className="border flex flex-col border-lightblue100 rounded-xl relative overflow-hidden bg-lightblue50  min-w-72">
                        <select
                            value={text.fontsize}
                            className="p-3"
                            onChange={(e) => {
                                updateText("fontsize", parseInt(e.target.value));
                            }}
                        >
                            {sizeDefaults.map((size, s) => (
                                <option key={s} value={size}>
                                    {size} px
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DetailText;
