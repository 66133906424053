import { useEffect, useState } from "react";
import { getUser, loginUser, updateUser } from "src/common/services/user";
import { useNavigate } from "react-router-dom";

import Preloader from "src/common/components/preloader/preloader";
import FormPhone from "src/common/components/form/phone";

const AdminLogin = (props) => {
    const navigate = useNavigate();
    const [number, setNumber] = useState("");

    const [fetching, setFetching] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);

    const signIn = async () => {
        setErrorMsg(false);

        setFetching(true);
        try {
            let login_res = await loginUser(`${number}`);
            const loginRes = login_res[0]

            console.log(`${number} :: `, loginRes);
            if (loginRes && loginRes.token) {
                localStorage.setItem("DIGITALU_token", loginRes.token);
            }

            if (loginRes && loginRes.userid) {
                try {
                    let user_res = await getUser({ id: loginRes.userid }, { userid: loginRes.userid, token: loginRes.token });
                    const theUser = user_res[0]
                    console.log(theUser);

                    if (theUser.isteam !== "true") {
                        await updateUser(loginRes.userid, { isteam: "true" }, false, loginRes.token);
                    }

                    if (theUser.error) {
                        navigate("/login");
                        //handle error here
                    } else {
                        localStorage.setItem("DIGITALU_user", JSON.stringify(theUser));

                        await new Promise((res) => setTimeout(() => res(), 500));
                        navigate("/admin/");
                    }
                } catch (error) {
                    setFetching(false);
                    console.log(`getUser error :` + error);
                }
            } else {
                setFetching(false);
            }
        } catch (error) {
            setFetching(false);
            console.log(`login error :` + error);
        }
    };

    return (
        <>
            {fetching && <Preloader type={"full"} />}
            <div className="flex flex-col gap-4">
                <div className="form-item">
                    <div className="form-item-label mb-2">Phone Number : </div>
                    <div className="flex flex-row gap-3 items-center">
                        <FormPhone
                            OnChange={(phone) => {
                                setNumber(phone);
                            }}
                        />
                    </div>
                </div>

                <div className="flex text-center gap-1 items-center justify-center">
                    or <div className="cursor-pointer text-blue">Login</div> using your email
                </div>
            </div>
            <div className="flex flex-col gap-4">
                <button
                    className="button text-base rounded-full px-8 py-6"
                    onClick={() => {
                        signIn();
                    }}
                >
                    Login
                </button>
                <div className="flex gap-2  items-center justify-center">
                    Don't have an account yet. <div className="cursor-pointer text-blue">Signup here.</div>
                </div>
            </div>
        </>
    );
};

export default AdminLogin;
