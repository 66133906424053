import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import AdminLayout from "src/layouts/admin-layout";
import Artboard from "src/modules/admin/studio/artboard/artboard";
import Sidebar from "src/modules/admin/studio/sidebar/sidebar";

import { LuRectangleHorizontal } from "react-icons/lu";
import { LuRectangleVertical } from "react-icons/lu";
import { LuSquare } from "react-icons/lu";

import { bgOverlay } from "src/common/services/generate";
import { getQueue } from "src/common/services/utils";
import { getRealVal, handleOverlay } from "./studio-funcs";

const Studio = (props) => {
    const navigate = useNavigate();
    const location = useLocation();

    const pollTimeout = useRef();
    const pollCounter = useRef(0);
    const pollLimit = 60;

    const screens = [
        {
            id: "desktop",
            icon: <LuRectangleHorizontal className="text-lg" />,

            width: 1280,
            height: 720,
        },
        {
            id: "mobile",
            icon: <LuRectangleVertical className="text-lg" />,

            width: 720,
            height: 1280,
        },
        {
            id: "square",
            icon: <LuSquare className="text-lg" />,

            width: 720,
            height: 720,
        },
    ];

    // imageURL, videoURL, audioURL, imageId, videoId, voiceId, voiceGender
    const initialVideoState = {
        avatar: { effect: "none", video: null },
        script: { text: "", speed: 1.0, voice: null },
        bg: { type: "color", value: "#ffffff" },
        texts: [
            // { id: uuidv4(), text: "Your Text", font: "Montserrat", fontsize: 32, color: "#000000" },
          
        ],
        gen_input: null,
        gen_output: null,
        overlay_input: null,
        overlay_output: null,
    };

    const [selScreen, setSelScreen] = useState(screens[1]);
    const [stageSize, setStageSize] = useState({ width: 0, height: 0 });
    const [selElem, setSelElem] = useState({ type: null, value: null });
    const [theVideo, setTheVideo] = useState(initialVideoState);
    const [history, setHistory] = useState([initialVideoState]); // Keeps track of state history
    const [currentIndex, setCurrentIndex] = useState(0); // Tracks current state in history
    const [redoStack, setRedoStack] = useState([]); // Tracks redoable states

    useEffect(() => {
        console.log(" -- theVideo ::", theVideo);
        //FOR OVERLAY
        if (
            theVideo.gen_output &&
            theVideo.gen_output.state === "complete" &&
            theVideo.gen_output.result &&
            theVideo.overlay_input === "init" &&
            theVideo.overlay_output === null
        ) {
            // handleOverlay({theVideo, stageSize, selScreen});
        }
        if (theVideo.overlay_output && theVideo.overlay_output.id && theVideo.overlay_output.state !== "complete") {
            //pollOverlay();
        }
    }, [theVideo]);

    useEffect(() => {
        console.log(" -- selElem ::", selElem);
    }, [selElem]);

    const pollOverlay = async () => {
        pollCounter.current++;
        if (pollCounter.current <= pollLimit) {
            if (pollTimeout.current) {
                clearTimeout(pollTimeout.current);
            }
            if (
                theVideo.overlay_output &&
                (theVideo.overlay_output?.state === "processing" || theVideo.overlay_output?.state === "" || !theVideo.overlay_output?.state)
            ) {
                try {
                    const que_res = await getQueue({ id: theVideo.overlay_output.id });
                    console.log("que_res :", que_res[0]);

                    theVideo.overlay_output = que_res[0];
                    setTheVideo({ ...theVideo });

                    if (que_res[0].state === "complete") {
                        console.log("completed");
                    } else {
                        if (que_res[0].state.indexOf("error") === -1) {
                            pollTimeout.current = setTimeout(async () => {
                                clearTimeout(pollTimeout.current);
                                await pollOverlay();
                            }, 6000);
                        }
                    }
                } catch (error) {
                    console.log(error);
                }
            } else {
                console.log("have not generated overlay yet");
            }
        } else {
            console.log("timed out");
        }
    };

    const updateTheVideo = (newVideoState) => {
        const newHistory = [...history.slice(0, currentIndex + 1), newVideoState];
        setTheVideo(newVideoState);
        setHistory(newHistory);
        setCurrentIndex(newHistory.length - 1);
        setRedoStack([]); // Clear redo stack whenever a new change is made
    };

    // Undo function
    const undo = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
            setTheVideo(history[currentIndex - 1]);
            setRedoStack([theVideo, ...redoStack]);
        }
    };

    // Redo function
    const redo = () => {
        if (redoStack.length > 0) {
            const nextState = redoStack[0];
            setRedoStack(redoStack.slice(1));
            setCurrentIndex(currentIndex + 1);
            setTheVideo(nextState);
        }
    };

    return (
        <AdminLayout
            type="full"
            // header={() => (
            //     <div className="flex flex-1 items-center ">
            //         <div className="flex flex-1 flex-row px-0 items-center gap-6">
            //             <div className="text-xl font-semibold">Studio</div>
            //         </div>
            //     </div>
            // )}
        >
            <div className="studio-container absolute left-0 top-0 h-full w-full flex-1 flex flex-row gap-4 relative overflow-hidden">
                <Sidebar
                    screens={screens}
                    stageSize={stageSize}
                    setStageSize={setStageSize}
                    selScreen={selScreen}
                    setSelScreen={setSelScreen}
                    theVideo={theVideo}
                    setTheVideo={updateTheVideo}
                    selElem={selElem}
                    setSelElem={setSelElem}
                />
                <Artboard
                    screens={screens}
                    stageSize={stageSize}
                    setStageSize={setStageSize}
                    selScreen={selScreen}
                    setSelScreen={setSelScreen}
                    theVideo={theVideo}
                    setTheVideo={updateTheVideo}
                    selElem={selElem}
                    setSelElem={setSelElem}
                    currentIndex={currentIndex}
                    redoStack={redoStack}
                    OnHistory={(dir) => {
                        switch (dir) {
                            case "undo":
                                undo();
                                break;
                            case "redo":
                                redo();
                                break;
                            default:
                                break;
                        }
                    }}
                />
            </div>
        </AdminLayout>
    );
};

export default Studio;
