import { useState } from "react";
import { HexColorPicker, RgbaColorPicker } from "react-colorful";
import "./select-bg.css";
import { LuPalette } from "react-icons/lu";

const SelectBgSolid = (props) => {
    const { theVideo, setTheVideo } = props;
    const hexColorRegex = /^#([A-Fa-f0-9]{6})$/;
    const solidDefaults = [
        { type: "color", value: "#ffffff" },
        { type: "color", value: "#F44A5E" },
        { type: "color", value: "#FF5C36" },
        { type: "color", value: "#FBD757" },
        { type: "color", value: "#61C884" },
        { type: "color", value: "#236AFF" },
        { type: "color", value: "#5E3DFC" },
    ];

    const [showPicker, setShowPicker] = useState(false);

    return (
        <div className="flex flex-col">
            <div className="gap-6 flex flex-col justify-center">
                <div className="flex flex-row items-center justify-between">
                    <div>Solid</div>
                    <div className="flex flex-row gap-2 items-center">
                        <div className="bg-lightblue100/40 rounded-2xl w-32">
                            {theVideo.bg.type === "color" && (
                                <input
                                    type="text"
                                    value={theVideo.bg.value}
                                    className="text-center !py-3"
                                    onChange={(e) => {
                                        let inputValue = e.target.value.replace(/[^A-Fa-f0-9#]/g, "");
                                        // Ensure it starts with a #
                                        if (inputValue.indexOf("#") !== 0) {
                                            inputValue = `#${inputValue}`;
                                        }

                                        if (inputValue.length > 7) {
                                            inputValue = inputValue.slice(0, 7);
                                        }
                                        setTheVideo((prev) => ({
                                            ...prev,
                                            bg: {
                                                type: "color",
                                                value: inputValue,
                                            },
                                        }));
                                    }}
                                />
                            )}
                        </div>

                        <div
                            className={`cursor-pointer rounded-2xl w-12 h-12 flex items-center justify-center border
                                ${showPicker ? "bg-lightblue100/50 border-white" : "border-lightblue100/90 text-black/40"}
                            `}
                            onClick={() => {
                                setShowPicker(!showPicker);
                            }}
                        >
                            <LuPalette className="text-xl" />
                        </div>
                    </div>
                </div>
                {showPicker &&  (
                    <div className="bg-lightblue100/40 p-8 rounded-2xl slideFromBot border-4 border-lightblue100/50 ">
                        <div className="custom-picker">
                            <HexColorPicker
                                color={theVideo.bg.value}
                                onChange={(val) => {
                                    setTheVideo((prev) => ({
                                        ...prev,
                                        bg: {
                                            type:'color',
                                            value: val,
                                        },
                                    }));
                                }}
                            />
                        </div>
                    </div>
                )}

                <div className="flex flex-row gap-1 ">
                    {solidDefaults.map((bg, b) => (
                        <div
                            key={b}
                            className={`cursor-pointer w-12 h-12 border-4 rounded-full
                                ${bg.value === theVideo.bg.value ? "border-lightblue100" : " border-white"}
                                `}
                            style={{ backgroundColor: bg.value, boxShadow: "inset 0 0 2px 0 rgba(0,0,0,0.4)" }}
                            onClick={() => {
                                setTheVideo((prev) => ({
                                    ...prev,
                                    bg: bg,
                                }));
                            }}
                        ></div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default SelectBgSolid;
