import { useRef, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const More = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const ref = useRef(null);
    const { campaign } = props;

    useEffect(() => {
        const handleClickOutside = (event) => {
            // event.stopPropagation();
            if (ref.current && !ref.current.contains(event.target)) {
                props.OnClose(true);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div className="more-box absolute top-0 left-0 w-full h-full z-30 slideFromLeftFast">
            <div
                ref={ref}
                className="absolute top-4 py-4  min-w-52 right-4 bg-white shadow-2xl shadow-zinc-500/30 border border-zinc-500/20 rounded-xl z-20 flex flex-col gap-2"
            >
                <div
                    className="px-6 py-1 transition cursor-pointer hover:opacity-70 hover:translate-x-0.5"
                    onClick={() => {
                        // const thevideo = { ...video };
                        // delete thevideo.id;
                        // delete thevideo.createdate;
                        // delete thevideo.result;
                        // delete thevideo.state;
                        // navigate("/admin/generate-video", { state: { todo:'regen' , data: thevideo } });
                    }}
                >
                    Edit
                </div>

                <div
                    className="border-t border-zinc-500/20 px-6 pt-3 transition cursor-pointer hover:opacity-70 hover:translate-x-0.5 text-rose-500"
                    onClick={() => {
                        const thecamp = { ...campaign };
                        thecamp.TYPE = "campaign";
                        navigate(`${location.pathname}`, { state: { show: "delete", data: thecamp } });
                        
                    }}
                >
                    Delete
                </div>
            </div>
        </div>
    );
};

export default More;
