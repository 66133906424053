import { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { RiMore2Fill } from "react-icons/ri";

import ApiBox from "src/common/components/api-box";
import Preloader from "src/common/components/preloader/preloader";
import More from "./card-gen-more";

import { dateFormatter } from "src/common/utils/utils";
import { generateVideo } from "src/common/data/apis-generate";

const DetailGenerated = (props) => {
    const { video } = props;
    const navigate = useNavigate();
    const location = useLocation();
    const [showMore, setShowMore] = useState();
    const [fetching, setFetching] = useState(false);

    return (
        <>
            {fetching && <Preloader />}
            <div className="flex flex-1 bg-white p-4 gap-6">
                <div className=" flex  max-h-600 min-h-600 relative overflow-hidden w-1/2 rounded-2xl">
                    {video.result && (
                        <video src={video.result} playsInline controls className="object-contain w-full max-h-600 min-w-400 bg-black" />
                        // <VideoPlayer src={thesrc} className="min-w-full h-full" preload={"none"} />
                    )}
                </div>

                <div className="flex flex-col px-3 pt-8 gap-3 justify-between w-1/2">
                    {showMore && (
                        <More
                            thesrc={video.result}
                            video={video}
                           
                            OnClose={() => {
                                setShowMore(false);
                            }}
                        />
                    )}
                     {video.result && (
                        <div
                            className="bg-white rounded-full p-3 cursor-pointer absolute right-3 top-5"
                            onClick={() => {
                                setShowMore(true);
                            }}
                        >
                            <RiMore2Fill className="text-xl" />
                        </div>
                    )}
                    <div className="flex-1 flex flex-col gap-4">
                        <div className="flex flex-col gap-2">
                            <div className="flex flex-row gap-4">
                                <div className="opacity-40 min-w-24">name : </div> <div>{video.name}</div>
                            </div>

                            <div className="flex flex-row gap-4">
                                <div className="opacity-40 min-w-24">created : </div> <div> {dateFormatter(video.createdate)}</div>
                            </div>
                            {video.text && (
                                <div className="flex flex-row gap-4">
                                    <div className="opacity-40 min-w-24">text : </div> <div> {video.text}</div>
                                </div>
                            )}
                            {video.useVoice && (
                                <div className="flex flex-row gap-4">
                                    <div className="opacity-40 min-w-24">useVoice : </div>
                                    <div>
                                        {video.useVoice} - {video.voiceId} {video.voiceGender}
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="mt-5">Generate this video :</div>
                        <ApiBox video={video} data={generateVideo(video)} />
                    </div>

                    {/* <div className="actions flex flex-row justify-between items-center pt-5  pb-2">
                        <div className="flex gap-5 items-center">
                            <button
                                className="button button-blue cursor-pointer rounded-3xl py-3 px-6 hover:translate-y-1 flex gap-4 items-center"
                                onClick={async () => {
                                    const thevideo = { ...video };
                                    delete thevideo.id;
                                    delete thevideo.createdate;
                                    delete thevideo.result;
                                    delete thevideo.state;
                                    //thevideo.type = "regenerate";
                                    navigate("/admin/generate-video", { state: { todo: "regen", data: thevideo } });
                                    // navigate(`${location.pathname}`, { state: { show: "create-video", data: thevideo } });
                                }}
                            >
                                Re-generate
                            </button>

                    

                            <a
                                href={video.result}
                                target="_blank"
                                download={video.result}
                                className="button cursor-pointer rounded-3xl py-3 px-6 hover:translate-y-1 flex gap-4 items-center"
                            >
                                Download
                            </a>
                        </div>

                        <div className="flex gap-5 items-center">
                        <button
                            className="button button-gray cursor-pointer rounded-3xl py-3 px-6 hover:translate-y-1 flex gap-4 items-center"
                            onClick={async () => {
                                props.OnDelete(video)
                            }}
                        >
                            Delete
                        </button>
                    </div>
                    </div> */}
                </div>
            </div>
        </>
    );
};

export default DetailGenerated;
