import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import Timeline from "src/common/components/timeline/timeline";
import TimelineNavi from "src/common/components/timeline/timeline-navi";
import AdminLayout from "src/layouts/admin-layout";
import Iicon from "src/common/components/iicon/iicon";
import Preloader from "src/common/components/preloader/preloader";
import AddInfo from "src/modules/admin/generated-videos/generate-video/add-info";
import AddVideo from "src/modules/admin/generated-videos/generate-video/add-video";

import { upload } from "src/common/services/utils";
import { generateVideo } from "src/common/services/generate";
import AddAudio from "src/modules/admin/generated-videos/generate-video/add-audio";
import AddText from "src/modules/admin/generated-videos/generate-video/add-text";

const GenerateVideo = (props) => {
    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [fetching, setFetching] = useState(false);

    const timelineData = [
        {
            id: "details",
            name: "Video Details",
        },
        {
            id: "add-video",
            name: "Add Video / Image",
        },
        {
            id: "add-audio",
            name: "Add Audio",
        },
        {
            id: "add-text",
            name: "Add Text",
        },
    ];

    const [timeline, setTimeline] = useState(timelineData);

    const [voiceTab, setVoiceTab] = useState(null);
    const [selectedTimeline, setSelectedTimeline] = useState(timeline[0]);
    const [canNext, setCanNext] = useState(false);
    const [genData, setGenData] = useState({ name: "" });

    useEffect(() => {
        if (params.campaignid) {
            //handleGenerate({ opr: "select", id: params.campaignid });
        }
    }, [params]);

    useEffect(() => {
        const todo = location?.state?.todo;
        const data = location?.state?.data;

        console.log("todo ::", todo, data);

        switch (todo) {
            case "create":
                setSelectedTimeline(timeline[0]);
                setGenData({ name: "", ...data });

                break;

            case "regen":
                setGenData({ ...data });
               
                break;

            default:
                break;
        }
    }, [location]);

    useEffect(() => {
        // console.log('genData :::', genData)
        handleTimelineValidation(selectedTimeline);
    }, [selectedTimeline, genData]);

    useEffect(() => {
        if (voiceTab === "direct") {
            const directTl = timelineData.filter((tl) => tl.id !== "add-text");

            setTimeline(directTl);
        } else {
            setTimeline(timelineData);
        }
    }, [voiceTab]);

    const handleTimelineValidation = () => {
        setCanNext(false);
        switch (selectedTimeline.id) {
            case "details":
                if (genData.name !== "") {
                    setCanNext(true);
                }
                break;
            case "add-video":
                if (genData.file || genData.videoId || genData.videoURL || genData.imageId || genData.imageURL) {
                    setCanNext(true);
                }
                break;

            case "add-audio":
                if (genData.audio || genData.voiceId || genData.audioURL || genData.voiceGender) {
                    setCanNext(true);
                }

            case "add-text":
                if (genData.text && genData.text !== "") {
                    setCanNext(true);
                }
                break;

            default:
                setCanNext(false);
                break;
        }
    };

    const navigateTimeline = (direction) => {
        const indexSelected = timeline.findIndex((timeline) => timeline.id === selectedTimeline.id);
        if (indexSelected) {
            switch (direction) {
                case "next":
                    if (timeline[indexSelected + 1]) {
                        setSelectedTimeline(timeline[indexSelected + 1]);
                    }
                    break;
                case "prev":
                    if (timeline[indexSelected - 1]) {
                        setSelectedTimeline(timeline[indexSelected - 1]);
                    }
                    break;

                default:
                    break;
            }
        }
    };

    const handleGenerate = async () => {
        setFetching(true);
        // console.log("genData :: ", genData);
        // imageURL, videoURL, audioURL, imageId, videoId, voiceId, voiceGender
        const gen_input = { ...genData };

        if (gen_input.file) {
            try {
                const file_res = await upload({ file: gen_input.file });
                console.log("file_res :: ", file_res);

                delete gen_input.file;
                gen_input.videoURL = file_res.data[0].cache;
            } catch (error) {
                console.log(error);
            }
        }

        if (gen_input.audio) {
            try {
                const audio_res = await upload({ file: gen_input.audio });
                console.log("audio_res :: ", audio_res);

                delete gen_input.audio;
                gen_input.audioURL = audio_res.data[0].cache;
            } catch (error) {
                console.log(error);
            }
        }

        if (gen_input.videoURL || gen_input.videoId) {
            gen_input.type = "video";
        } else if (gen_input.imageURL || gen_input.imageId) {
            gen_input.type = "image";
        }

        delete gen_input.URL;

        console.log("gen_input (input) ::", gen_input);
        const gen_res = await generateVideo(gen_input);
        console.log("gen_res (output) ::", gen_res);

        navigate("/admin/generated-videos");

        // setFetching(false);
        //  props.OnSuccess(true);
    };

    const canSubmit = () => {
        if (genData.name !== "") {
            switch (voiceTab) {
                case "direct":
                    if (
                        (genData.file || genData.videoId || genData.videoURL || genData.imageId || genData.imageURL) &&
                        (genData.audio || genData.voiceId || genData.audioURL)
                    ) {
                        return true;
                    }
                    break;

                case "default":
                    if ((genData.text !== "" && genData.file) || genData.videoId || genData.videoURL || genData.imageId || genData.imageURL) {
                        return true;
                    }
                    break;

                case "fromId":
                    if (
                        genData.text !== "" &&
                        (genData.file || genData.videoId || genData.videoURL || genData.imageId || genData.imageURL) &&
                        genData.voiceId
                    ) {
                        return true;
                    }
                    break;
                default:
                    return false;
            }
        } else {
            return false;
        }
    };

    return (
        <>
            {fetching && <Preloader />}
            <AdminLayout
                header={() => (
                    <div className="flex items-center">
                        <div className="text-xl font-semibold">Generate Video</div>

                        <Timeline
                            data={timeline}
                            canNext={canNext}
                            selected={selectedTimeline}
                            OnSelect={(item) => {
                                setSelectedTimeline(item);
                            }}
                        />
                    </div>
                )}
                footer={() => (
                    <div className="flex justify-between gap-8 items-center">
                        <TimelineNavi
                            data={timeline}
                            selected={selectedTimeline}
                            direction={"back"}
                            onClick={(prevTimeline) => {
                                setSelectedTimeline(prevTimeline);
                            }}
                        />

                        {/* {selectedTimeline.id !== "details" ||
                            (selectedTimeline.id === "details" && genData.id && (
                                <TimelineNavi
                                    data={timeline}
                                    canNext={canNext}
                                    selected={selectedTimeline}
                                    direction={"next"}
                                    onClick={(nextTimeline) => {
                                        setSelectedTimeline(nextTimeline);
                                    }}
                                />
                            ))} */}

                        <TimelineNavi
                            data={timeline}
                            canNext={canNext}
                            selected={selectedTimeline}
                            direction={"next"}
                            onClick={(nextTimeline) => {
                                setSelectedTimeline(nextTimeline);
                            }}
                        />

                        {selectedTimeline?.id === "add-text" && (
                            <button
                                disabled={(genData.text && genData.text === "") || !genData.text}
                                className="button flex gap-3 pr-3 items-center"
                                onClick={async () => {
                                    // if (canSubmit()) {
                                    await handleGenerate();
                                    // }
                                }}
                            >
                                <div>Finish</div>
                                <Iicon icon={"arrow-right-white"} />
                            </button>
                        )}

                        {selectedTimeline?.id === "add-audio" && voiceTab === "direct" && (
                            <button
                                disabled={!genData.audio && !genData.audioURL}
                                className="button flex gap-3 pr-3 items-center"
                                onClick={async () => {
                                    // if (canSubmit()) {
                                    await handleGenerate();
                                    // }
                                }}
                            >
                                <div>Finish</div>
                                <Iicon icon={"arrow-right-white"} />
                            </button>
                        )}
                    </div>
                )}
            >
                {selectedTimeline?.id === "details" && (
                    <div className="flex flex-col gap-8 py-10 w-full max-w-screen-lg mx-auto slideFromRight">
                        <AddInfo
                            genData={genData}
                            OnSet={(data) => {
                                setGenData({ ...data });
                                navigateTimeline("next");
                            }}
                        />
                    </div>
                )}

                {selectedTimeline?.id === "add-video" && (
                    <div className="flex flex-col w-full  mx-auto slideFromRight">
                        <AddVideo
                            {...props}
                            genData={genData}
                            OnSet={(data) => {
                                setGenData({ ...data });
                                // navigateTimeline("next");
                            }}
                        />
                    </div>
                )}

                {selectedTimeline?.id === "add-audio" && (
                    <div className="flex flex-col w-full  mx-auto slideFromRight">
                        <AddAudio
                            {...props}
                            genData={genData}
                            OnSet={(data) => {
                                setGenData({ ...data });
                            }}
                            OnVoiceTab={(tab) => {
                                setVoiceTab(tab.id);
                            }}
                        />
                    </div>
                )}

                {selectedTimeline?.id === "add-text" && (
                    <div className="flex flex-col gap-8 py-10 w-full max-w-screen-lg mx-auto slideFromRight">
                        <AddText
                            {...props}
                            genData={genData}
                            OnSet={(data) => {
                                setGenData({ ...data });
                            }}
                        />
                    </div>
                )}
            </AdminLayout>
        </>
    );
};

export default GenerateVideo;
